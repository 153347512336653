
<div *ngIf="isV2CaptchaRequired" 
class="captcha-container mb-3">
<ngx-recaptcha2
  [siteKey]="siteKey"
  (success)="handleSuccess($event)"
  (error)="handleError($event)"
  (expired)="handleExpired($event)">
</ngx-recaptcha2>
</div>
<section class="mt-5" *ngIf="!isV2CaptchaRequired">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-xs-12 col-lg-6 col-md-6 mb-4 offset-md-3">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <form  [formGroup]="userForm">
                                <mat-form-field class="col-12">
                                    <input matInput placeholder="Email" name="Email" formControlName="email" required>
                                </mat-form-field>
                                <div class="close-btn right-3" *ngIf="userForm.value.firstName"
                                    (click)="userForm.patchValue({email :''});formValidation(0)">
                                </div>
                          
                            <button mat-raised-button class="blue_button float-end mb-5" (click)="formValidation(1)">Submit</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>