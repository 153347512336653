import { Component} from '@angular/core';
import { ApiService } from './services/api.service';
import packageInfo from '../../package.json';
import { Subscription } from 'rxjs';
import { HeaderService } from './services/header.service';
import { GtmService } from './services/gtm.service';
import version from '../assets/version.json';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  public version: string = packageInfo.version;
  title = 'standardc-frontend-web';
  createOnlineSub:Subscription;
  public lat;
  public lng;
  zoom;

  constructor( 
    private _api : ApiService,
    private headerService: HeaderService,
    private gaService: GtmService,
    private cookieService : CookieService
    ) {
        this.gaService.trackEvent('page_view');
        this.createOnlineSub = this._api.createOnline$().subscribe((status) => {
          !status && this._api.openSnackBar('No Internet Connection Available', 'Error')
        });
      }
     
  ngOnInit() {

    // this.router.events.subscribe(event => {
    //   if (event instanceof RouteConfigLoadStart) {
    //     this.spinner.show();
    //   } else if (event instanceof RouteConfigLoadEnd) {
    //     this.spinner.hide();
    //   }
    // });
    this.getVersions();
    sessionStorage.setItem( 'version', packageInfo.version);
    if(packageInfo.version.toString() !== version.build_version.toString()){
      window.location.reload();
      localStorage.clear();
      sessionStorage.clear();
      this.cookieService.deleteAll('/');
    }
    this.getLocation();
    this.headerService.getHeaderData().subscribe((response) => {
      const logoURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['logo_path']+`?timestamp=${new Date().getTime()}`;
      const faviconURL = response['data']['static_base_url']+'/'+response['data']['web_hf']['favicon_path']+`?timestamp=${new Date().getTime()}`;
      const headerContent = response['data']['web_hf']['header'];
      const footerContent = response['data']['web_hf']['footer'];
      // const backgroundColor = response['data']['web_hf']['background_color'];
      const highLightColor = response['data']['web_hf']['highlight_color'];
      document.documentElement.style.setProperty('--dynamic-bg-color', highLightColor);
      document.documentElement.style.setProperty('--dynamic-section-bg-color', highLightColor);

      this.setFavicon(faviconURL);
      this.setLogo(logoURL);
      this.setHeader(headerContent);
      this.setFooter(footerContent);

    });
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 16;
        localStorage.setItem('latitude', this.lat);
        localStorage.setItem('longitude', this.lng);
        console.log("position", position)
      });
    }else{
      console.log("User not allowed")
    }
  }

  setLogo(url: string): void {
    const imgElement = document.getElementById('dynamicLogo') as HTMLImageElement;
    if (imgElement) {
        imgElement.src = url;
        imgElement.alt = 'Logo';
    }
  }
  
  setFavicon(url: string): void {
    const favicon = document.getElementById('dynamicFavicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = url;
    }
  }
  
  setHeader(header: string): void {
    const headerTitle = document.getElementById('dynamicHeader');
    if (headerTitle) {
      headerTitle.innerHTML = header;
    }
  }
  
  setFooter(footer: string): void {
    const footerTitle = document.getElementById('dynamicFooter');
    if (footerTitle) {
      footerTitle.innerHTML = footer;
    }
  }

  ngOnDestroy(): void {
    if (this.createOnlineSub) {
      this.createOnlineSub.unsubscribe();
    }  }
  getVersions(){
    this._api.functionGET('version').subscribe((response)=>{
      sessionStorage.setItem('server_version' , JSON.stringify(response['data']));
    });
  }

}
