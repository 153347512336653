import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-custom-accordion',
  template: `
      <mat-accordion>
      <ng-container *ngFor="let panel of field.fieldGroup; let i = index">
        <mat-expansion-panel 
          [expanded]="panel.templateOptions.expanded" 
           (opened)="onPanelOpened(i)"
          (closed)="onPanelClosed(i)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <span  class="status-indicator" class="checkmark">
              <ng-container [ngSwitch]="isSectionValid(i, panel.templateOptions.required)">
                <mat-icon *ngSwitchCase="'Uploaded'" class="Uploaded">check_circle</mat-icon>
                <mat-icon *ngSwitchCase="'NotApplicable'" class="NotApplicable">remove_circle</mat-icon>
                <mat-icon *ngSwitchCase="'Invalid'" class="invalid">cancel</mat-icon>
                <mat-icon *ngSwitchDefault class="Pending">radio_button_unchecked</mat-icon>
              </ng-container>
              </span>
              <div *ngIf="panel.templateOptions.required">
                {{ panel.templateOptions.title }} <span class="text-danger">*</span>
              </div>
              <div *ngIf="!panel.templateOptions.required">
                {{ panel.templateOptions.title }}
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <formly-field *ngFor="let f of panel.fieldGroup" [field]="f"></formly-field>
          <mat-action-row>
          <ng-container *ngIf="i === 0">
            <button mat-button color="primary" style="margin-left: auto !important;" (click)="nextAccordion(i)">Next</button>
          </ng-container>
          <ng-container *ngIf="i > 0">
            <button mat-button color="primary" *ngIf="i>0 || i>1" (click)="previousAccordion(i)">Previous</button>
            <button mat-button color="primary" style="margin-left: auto;" *ngIf="i < field.fieldGroup.length - 1" (click)="nextAccordion(i)">Next</button>
          </ng-container>
          </mat-action-row>
        </mat-expansion-panel>
      </ng-container>
      </mat-accordion>
  `,
  styles: [`
    mat-action-row {
      display: flex;
      justify-content: space-between;
    }
    ::ng-deep .mat-expansion-panel {
      margin-bottom: 10px !important;
    }
    ::ng-deep .mat-expansion-panel-header-title{
        font-weight: 700 !important;
    }
    ::ng-deep .mat-action-row {
        padding: 16px 8px 16px 8px !important;
    }
    ::ng-deep .mat-action-row .mat-button-base, .mat-action-row .mat-mdc-button-base {
        margin-left: 0px !important;
    }
    ::ng-deep .mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
        color: #3f51b5 !important;
        font-weight: 600 !important;
    }
    .checkmark {
      color: green;
      font-weight: bold;
      margin-right: 6px;
      vertical-align: text-bottom;
    }
    .status-indicator {
        margin-left: 10px;
        font-size: 18px;
        vertical-align: middle;
      }
      .mat-icon.Uploaded {
  color: green;
}
.mat-icon.NotApplicable {
  color: #d4b033;
}
.mat-icon.Pending {
  color: lightgray;
    background: lightgray;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
.mat-icon.invalid {
  color: red;
}
  ::ng-deep .text-wrap{
    text-align: left;
  line-height: 1.5;
  word-wrap: break-word !important;
}
  `],
})
export class FormlyAccordionTypeComponent extends FieldType  {

  // shouldDisplayPanel(panel: any, index): boolean {
  //   const description = panel.templateOptions?.description?.toLowerCase();
  //   if(this.model.is_cannabis_inspection === 'Y'){
  //     return description === 'cannabis' || description === 'both';
  //   }else{
  //     return description === 'non_cannabis' || description === 'both';
  //   }
  // }

  closedPanels: Set<number> = new Set();


  // onPanelOpened(index: number) {
  //   this.field.fieldGroup[index].templateOptions.expanded = true;
  // }
  onPanelOpened(index: number) {
    this.field.fieldGroup[index].templateOptions.expanded = true;
    
  }
  
  onPanelClosed(index: number) {
    this.field.fieldGroup[index].templateOptions.expanded = false;
    this.closedPanels.add(index);
  }

  nextAccordion(index: number) {
    const currentIndex = index
    if (currentIndex !== -1 && currentIndex < this.field.fieldGroup.length - 1) {
      this.field.fieldGroup[currentIndex].templateOptions.expanded = false;
      this.field.fieldGroup[currentIndex + 1].templateOptions.expanded = true;
    }
  }
  
  previousAccordion(index: number) {
    const currentIndex = index
    if (currentIndex !== -1 && currentIndex > 0) {
      this.field.fieldGroup[currentIndex].templateOptions.expanded = false;
      this.field.fieldGroup[currentIndex - 1].templateOptions.expanded = true;
    }
  }

  isSectionValid(index: number, isRequired): string {
    const section = this.field.fieldGroup[index];
  
    // if (section?.fieldGroup?.[0]?.templateOptions?.value === undefined) {
    //   return 'Pending';
    // }
    // If the panel was opened and closed without any action, mark as "Invalid"
    if (!section?.fieldGroup?.[0]?.templateOptions?.expanded && this.closedPanels.has(index) && section?.fieldGroup?.[0]?.templateOptions?.value === "" && isRequired) {
      return 'Invalid';
    }
    if (!section?.fieldGroup?.[0]?.templateOptions?.expanded && this.closedPanels.has(index) && section?.fieldGroup?.[0]?.templateOptions?.value === "" && !isRequired) {
      return 'Pending';
    }
    if(section.fieldGroup[0].templateOptions.value === 'Uploaded'){
      return 'Uploaded'
    }
    if(section.fieldGroup[0].templateOptions.value === 'Not Applicable'){
      return 'NotApplicable'
    }
   
  }

}
