import { Component, ViewChild, TemplateRef, OnInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormlyFieldConfig, FieldArrayType } from '@ngx-formly/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';


@Component({
  selector: 'app-formly-custom-doc-table',
  template: `
    <div class="table-responsive">
    <table class="table table-bordered table-striped">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data'];let i = index">
          <td *ngFor="let col of to['columns']">
            <div *ngIf="col.key === 'updated'">{{ row[col.key] | date:'MMM dd, yyyy, hh:mm:ss a' }}</div>
            <div *ngIf="col.key != 'updated'">{{ row[col.key] }}</div>
          </td>

          <td>
            <mat-icon class="text-primary" (click)="viewDoc(row['df_document_attach_id'])">visibility</mat-icon>
            <mat-icon class="text-danger ms-3" (click)="deleteRow(row['df_document_attach_id'])">delete</mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  `,
  styles: [
    `
   .table-responsive {
    overflow-x: auto;
    width: 100%;
}
.table-bordered {
    width: 100%;
    table-layout: auto; /* Ensures equal column width */
}
  table td:nth-child(2), 
table th:nth-child(2) {
    width: 50%; /* Adjust width as needed */
}
    table td:last-child, 
table th:last-child {
    width: 7%; /* Adjust as needed */
}
    .table-bordered th,
.table-bordered td {
    padding: 8px;
    word-wrap: break-word; /* Prevents text overflow */
}
      @media screen and (max-width: 800px) {
        .desktop-hidden {
          display: initial;
        }
        .mobile-hidden {
          display: none;
        }
      }
      @media screen and (min-width: 800px) {
        .desktop-hidden {
          display: none;
        }
        .mobile-hidden {
          display: initial;
        }
      }
      ::ng-deep .text-secondary {
        color: #d3d3d3 !important;
        width: 22px;
        height: 22px;
      }
    `
  ],
})
export class FormlyCustomDocTable extends FieldArrayType {

  constructor(
    private cdr: ChangeDetectorRef
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    , private cookieService: CookieService
  ) {
    super();
  }

  @ViewChild('defaultColumn') public defaultColumn: TemplateRef<any>;
  @ViewChild('myTable') table: any;
  @Output() deleteAction = new EventEmitter<any>();

  // deleteRow(row: any) {
  //   if (this.to.onDelete) {
  //     this.to.onDelete(row); 
  //   } else {
  //     console.error('onDelete function is not defined in templateOptions.');
  //   }
  // }

  get rows() {
      return this.to.data || [];
    }


deleteRow(doc_id: any){
  if (this.to.onDelete) {
    this.spinner.show();
      const apiData = {
       instance_id: localStorage.getItem('instance_id'),
        email: localStorage.getItem('email'),
        df_document_attach_id: doc_id
      }
      this._api.functionPOST('web/deleteDocument', apiData).subscribe((response) => {
        if(response['success']){
          this._api.openSnackBar(response['data'], 'Success');
          this.spinner.hide();
            this.to.onDelete(true);
        }
        
      });
    }
  }

  viewDoc(doc_id: any, v2token?){
    let isV2Required;
    if(v2token){
      isV2Required = true;
    }
    this.spinner.show();
    const apiData = {
      df_document_attach_id: doc_id,
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      isV2CaptchaRequired: isV2Required
    };
    this._api.functionPOST('web/viewDocument', apiData, v2token).subscribe((response) => {
      console.log('-------res', response);
      window.open(response.data.fileurl, '_blank');
    });
    this.spinner.hide();
    // const url = 'web/viewDocument/' + doc_id;
    // this._api.downloadFile(url,'?');
  }

}
