<div *ngIf="isV2CaptchaRequired" 
class="captcha-container mb-3">
<ngx-recaptcha2
  [siteKey]="siteKey"
  (success)="handleSuccess($event)"
  (error)="handleError($event)"
  (expired)="handleExpired($event)">
</ngx-recaptcha2>
</div>
<section class="mt-5" *ngIf="!isV2CaptchaRequired">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 mb-4" *ngIf="dynamicSection">
                <mat-card class="cust_card">
                    <mat-card-content>
                        <p [innerHtml]="dynamicSection" ></p>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="col-sm-6 col-xs-12 col-lg-6 col-md-6 mb-4 offset-md-3" *ngIf="!isSubmissionSuccess">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <p>We just need to verify a few details about the primary applicant before we begin the application process.</p>
                        <form  [formGroup]="userForm">
                            <mat-form-field class="col-12">
                                <input matInput placeholder="First Name" name="First Name" formControlName="firstName" required>
                                </mat-form-field>
                                <div *ngIf="userForm.controls.firstName.touched && userForm.controls.firstName.invalid" style="margin-top: -35px; color:red">
                                    <small *ngIf="userForm.controls.firstName.errors?.required">First Name is required.</small>
                                    <small *ngIf="userForm.controls.firstName.errors?.minlength">First name must be at least 2 characters long.</small>
                                  </div>
                                <div class="close-btn right-3" *ngIf="userForm.value.firstName"
                                    (click)="userForm.patchValue({firstName :''});formValidation(0)">
                                </div>

                                <mat-form-field class="col-12 mt-3">
                                    <input matInput placeholder="Last Name" name="Last Name" formControlName="lastName" required>
                                </mat-form-field>
                                <div *ngIf="userForm.controls.lastName.touched && userForm.controls.lastName.invalid" style="margin-top: -35px; color:red">
                                    <small *ngIf="userForm.controls.lastName.errors?.required">Last Name is required.</small>
                                    <small *ngIf="userForm.controls.lastName.errors?.minlength">Last name must be at least 2 characters long.</small>
                                  </div>
                                <div class="close-btn right-3" *ngIf="userForm.value.lastName"
                                    (click)="userForm.patchValue({lastName :''});formValidation(0)">
                                </div>

                                <mat-form-field class="col-12 mt-3">
                                    <input matInput placeholder="Email" name="Email" formControlName="email" required>
                                </mat-form-field>
                                <div *ngIf="userForm.controls.email.touched && userForm.controls.email.invalid" style="margin-top: -35px; color:red">
                                    <small *ngIf="userForm.controls.email.errors?.required">Email is required.</small>
                                    <small *ngIf="userForm.controls.email.errors?.email">Invalid Email.</small>
                                  </div>
                                <div class="close-btn right-3" *ngIf="userForm.value.email"
                                    (click)="userForm.patchValue({email :''});formValidation(0)">
                                </div>
                            
                            <button mat-raised-button class="blue_button float-end mb-5" (click)="formValidation(1)">Submit</button>
                            <button mat-raised-button class="float-end mb-5 me-4" (click)="goToHome()">Cancel</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>


            <div class="col-sm-6 col-xs-12 col-lg-6 col-md-6 mb-4 offset-md-3" *ngIf="isSubmissionSuccess">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <img src="assets/images/approve.svg" class="center mb-3" alt="Approved" height="40" />
                       <h5>An email has been sent to the provided address. Please check your inbox and verify the email.</h5>
                    </mat-card-content>
                </mat-card>
            </div>

        </div>
    </div>
</section>