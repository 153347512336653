import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

 year = new Date().getUTCFullYear();
 frontend_build_version : any;

 constructor(
  private _api: ApiService
  , private spinner: NgxSpinnerService
 ) { }

 removeLastHyphen(value: string): string {
  const index = value.lastIndexOf('-');
  return index !== -1 ? value.slice(0, index) : value;
}

ngOnInit(): void {
  this.frontend_build_version = this.removeLastHyphen(sessionStorage.getItem( 'version'));
 }
 
}
