import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FieldType } from '@ngx-formly/core';
import { FormlyDynamicModalComponent } from '../popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { EditIndividualComponent } from '../popups/edit-individual/edit-individual.component';

@Component({
  selector: 'app-formly-custom-individual-table',
  template: `
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th *ngFor="let col of to['columns']">{{ col.label }}</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of to['data']; let i = index">
          <td *ngFor="let col of to['columns']">{{ row[col.key] }}</td>
          <td>
          <button class="blue_button" mat-raised-button (click)="editRow(row, i)">Edit</button>
          <button class="ms-3" mat-raised-button color="danger" (click)="deleteRow(i)">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  `,
  styles: [
    `
    .table-responsive {
  overflow-x: scroll; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents text from wrapping */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #ccc transparent; /* For Firefox */
}

/* For WebKit Browsers (Chrome, Safari, Edge) */
.table-responsive::-webkit-scrollbar {
  height: 8px; /* Sets scrollbar height */
}

.table-responsive::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar color */
  border-radius: 4px;
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}
      @media screen and (max-width: 800px) {
        .desktop-hidden {
          display: initial;
        }
        .mobile-hidden {
          display: none;
        }
      }
      @media screen and (min-width: 800px) {
        .desktop-hidden {
          display: none;
        }
        .mobile-hidden {
          display: initial;
        }
      }
    `,
  ],
})
export class FormlyCustomIndividualTable extends FieldType implements OnInit {
  constructor(
    private dialog: MatDialog,
  ) {
    super();
  }
  editIndModel = {};
  isEditing = false;
  editingIndex: number | null = null;

  ngOnInit(): void {
    // console.log('----------------------indi form', this.to.indForm);
  }
  deleteRow(index: number): void {
    const currentData = this.model.dynamicIndData;
    currentData.splice(index, 1);
    this.model.dynamicIndData = currentData
  }

  editRow(row: any, index: number): void {
    this.isEditing = true;
    this.editingIndex = index;
    this.editIndModel = { ...this.model.dynamicIndData[index] };
    this.openRightSlideModalEditInd(this.editIndModel)
  }

  openRightSlideModalEditInd(row: any) {
    const dialogRef = this.dialog.open(EditIndividualComponent, {
        width: '0px',
        position: { right: '0' },
        panelClass: 'slide-in-right',
        data: {
          tabType: 'edit_key_person_tab',
          editModel: { ...row },
          indJsonForm: this.to.indForm,
          modalWidth:'100'},
        disableClose: true,
      });

      dialogRef.afterOpened().subscribe(() => {
        const dialogElement = document.querySelector('.slide-in-right .modal-container');
        if (dialogElement) {
          dialogElement.classList.add('open');
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          console.log('-----------------result', result);
          this.to.onClick(result, this.editingIndex);
        }
      });
  }
}
