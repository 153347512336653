<div class="modal-container" [style.width.%]="modalWidth">
    <div class="modal-header">
        <h2>Edit Individual</h2>
        <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
      </div>
    
      <form [formGroup]="editIndForm" (ngSubmit)="onEditIndSubmit()"  style="margin-bottom: 60px;">
        <formly-form [form]="editIndForm" [fields]="editFields" [model]="editModel"></formly-form>
      
        <button class="blue_button float-end ms-3 me-3" mat-raised-button  type="submit" (click)="onEditIndSubmit()">Update</button>
        <button class="float-end" mat-raised-button color="secondary" (click)="close()">Close</button>
      </form>
</div>
