import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-application-cancel',
  templateUrl: './application-cancel.component.html',
  styleUrls: ['./application-cancel.component.css']
})
export class ApplicationCancelComponent implements OnInit {
  userForm: FormGroup;
  hide = true;
  isV2CaptchaRequired: boolean = false;
  siteKey: string = environment.reCaptcha2SiteKey
  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private formBuilder: FormBuilder
   , private cookieService: CookieService
   , private dialog: MatDialog
   ) {
     this.userForm = formBuilder.group({
       motherSirName: new FormControl('', [Validators.required]),
       lastName: new FormControl('', [Validators.required]),
       email: new FormControl('', [Validators.required, Validators.email])
     })
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      localStorage.setItem('instance_id',  params.get('id'));
    });
  }

  formValidation(tag: number){
   
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;
    if(!lastName){
      if(tag==1){
        this._api.openSnackBar('Enter Last Name', 'Error');
      }
      return;
    }
    if(!email){
      if(tag==1){
        this._api.openSnackBar('Enter Email', 'Error');
      }
      return;
    }
    if(!motherSirName){
      if(tag==1){
        this._api.openSnackBar("Enter Mother's maiden name", 'Error');
      }
      return;
    }
    if (lastName.trim().length < 2) {
      if (tag == 1) {
        this._api.openSnackBar('Last name must be at least 2 characters long', 'Error');
        return;
      }

    }
  this.submitAccessVerification();

  }

  submitAccessVerification(v2token?){
    this._api.checkLocationAccess().subscribe((isAllowed) => {
      if (!isAllowed) return;
      let isV2Required;
    if(v2token){
      isV2Required = true;
    }
    let motherSirName = this.userForm.get('motherSirName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;
    this.spinner.show();
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      lastname: lastName,
      email: email,
      mother_maiden_name: motherSirName,
      latitude: localStorage.getItem('latitude'),
      longitude:  localStorage.getItem('longitude'),
      isV2CaptchaRequired: isV2Required
    };
    this._api.functionPOST('web/authendicateUser', apiData, v2token).subscribe((response) => {
      if(response['success'] && !response['data']['isV2CaptchaRequired']){
        this.isV2CaptchaRequired = false;
      // this.cookieService.set('instance_id', response['data']['df_instance_id']);
      // this.cookieService.set('token', response['data']['token']);
      // this.cookieService.set('email', response['data']['email']);

      localStorage.setItem('instance_id', response['data']['df_instance_id']);
      localStorage.setItem('authToken', response['data']['token']);
      localStorage.setItem('email', response['data']['email']);

      const dialogRef = this.dialog.open(BasicPopupComponent, {
        maxWidth: '600px'
        , data: {
          pageTitle: "Reason",
          pageType: 'cancel_application',
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.cancelApplication(result);
        }
      });
      
    }else {
      this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'] || false;
    }
    this.spinner.hide();
    }, err => {
      this.isV2CaptchaRequired = false;
    });
  });
  }

  goToHome(){
    this.router.navigate(['/']);
  }

  cancelApplication(reason: any) {
    this.spinner.show();
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      comment:  reason,
      latitude: localStorage.getItem('latitude'),
      longitude:  localStorage.getItem('longitude')
    };
    this._api.functionPOST('web/cancelApplication', apiData).subscribe((response) => {
    if(response['success']){
      this.goToHome();
      // this.router.navigate(['application-status/'+localStorage.getItem('instance_id')]);
    } else {
      this.goToHome();
    }
    },
    err => {
      this.goToHome();
    });
    this.spinner.hide();
  }

  handleSuccess(captchaResponse: string): void {
    console.log('CAPTCHA Response:', captchaResponse);
    this.submitAccessVerification(captchaResponse);
    // Use the response token for server-side validation
  }
  
  handleError(error: any): void {
    console.error('CAPTCHA Error:', error);
  }
  
  handleExpired(): void {
    console.warn('CAPTCHA Expired');
    // Optionally reset the CAPTCHA if needed
  }
}

