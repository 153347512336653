import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { DomSanitizer } from '@angular/platform-browser';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-verification',
  templateUrl: './user-verification.component.html',
  styleUrls: ['./user-verification.component.css']
})
export class UserVerificationComponent implements OnInit {
  templateID: any;
  userForm: FormGroup;
  dynamicSection: any;
  isSubmissionSuccess = false;
  isV2CaptchaRequired: boolean = false;
  siteKey: string = environment.reCaptcha2SiteKey
  
  constructor(
   private route: ActivatedRoute
  , private router: Router
  , private _api: ApiService
  , private spinner: NgxSpinnerService
  , private formBuilder: FormBuilder
  , private cookieService: CookieService
  , private sanitizer: DomSanitizer
  , private dialog: MatDialog
  ) {
    this.userForm = formBuilder.group({
      firstName: new FormControl('', [Validators.required,Validators.minLength(2)]),
      lastName: new FormControl('', [Validators.required,Validators.minLength(2)]),
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  ngOnInit(): void {
    this.templateID = this.route.snapshot.paramMap.get('id');
    // console.log('---------------idddd', this.templateID);

    this.route.queryParamMap.subscribe(params => {
      // console.log('---------------idddd1', params);

      if(params.get('template_id')){
        
        this.templateID = params.get('template_id');
        // console.log('---------------idddd2', this.templateID);
      }

      // console.log('---------------idddd3', this.templateID);
    
    });

    if(this.templateID){
      this.getTemplateSection();
    }
  }

  getTemplateSection() {
    this.spinner.show();
    const apiData = {
      template_id: this.templateID
    };
    this._api.functionPOST('web/getWebSection', apiData).subscribe((response) => {
     this.dynamicSection = this.sanitizer.bypassSecurityTrustHtml(response['data']['section']);
      this.spinner.hide();
    });
  }

  formValidation(tag: number){
    
    let firstName = this.userForm.get('firstName')?.value;
    let lastName = this.userForm.get('lastName')?.value;
    let email = this.userForm.get('email')?.value;
    if(!firstName){
      if(tag==1){
        this._api.openSnackBar('Enter First Name', 'Error');
      }
      return;
    }
    if(!lastName){
      if(tag==1){
        this._api.openSnackBar('Enter Last Name', 'Error');
      }
      return;
    }
    if(!email){
      if(tag==1){
        this._api.openSnackBar('Enter Email', 'Error');
      }
      return;
    }
    if (firstName.trim().length < 2) {
      if (tag == 1) {
        this._api.openSnackBar('First name must be at least 2 characters long', 'Error');
        return;
      }

    }
    if (lastName.trim().length < 2) {
      if (tag == 1) {
        this._api.openSnackBar('Last name must be at least 2 characters long', 'Error');
        return;
      }

    }
  this.submitUserVerification();
  }

submitUserVerification(v2token?){
  this._api.checkLocationAccess().subscribe((isAllowed) => {
    if (!isAllowed) return;
  let isV2Required;
  if(v2token){
    isV2Required = true;
  }
  let firstName = this.userForm.get('firstName')?.value;
  let lastName = this.userForm.get('lastName')?.value;
  let email = this.userForm.get('email')?.value;

  this.spinner.show();
  const apiData = {
    firstname: firstName,
    lastname: lastName,
    email: email,
    template_id: this.templateID,
    latitude: localStorage.getItem('latitude'),
    longitude:  localStorage.getItem('longitude'),
    isV2CaptchaRequired: isV2Required
  };
  this._api.functionPOST('web/sendEmailVerification', apiData, v2token).subscribe((response) => {
    if(response['success'] && !response['data']['isV2CaptchaRequired']){
      this.isV2CaptchaRequired = false;
      localStorage.setItem('firstname', response['data']['firstname']);
        localStorage.setItem('lastname', response['data']['lastname']);
        localStorage.setItem('instance_id', response['data']['df_instance_id']);
        localStorage.setItem('authToken', response['data']['token']);
        localStorage.setItem('email', response['data']['email']);

        if(response['data']['token']){
          this.router.navigate(['selected-application/'+response['data']['df_instance_id']]);
        }
        else if(response['data']['show_app_status']){
          this.router.navigate(['app-access-verification/'+response['data']['df_instance_id']]);
        }
        else if(response['data']['is_app_inprogress']){
          // Open modal with info
          const dialogRef = this.dialog.open(BasicPopupComponent, {
            maxWidth: '600px'
            , data: {
              pageTitle: "Alert",
              pageType: 'app_in_progress',
            }
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.router.navigate(['/']);
            }
          });

      }else {
        // this._api.openSnackBar(response['data'], 'Success');
        this.isSubmissionSuccess = true;
      }
  
   }else if(response['success'] && response['data']['isV2CaptchaRequired']) {
    this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];
  }else {
    // this._api.openSnackBar(response['data'], 'Success');
    this.isSubmissionSuccess = true;
  }
    this.spinner.hide();
  }, err => {
    this.isV2CaptchaRequired = false;
  });
});
}

goToHome(){
  this.router.navigate(['/']);
}

handleSuccess(captchaResponse: string): void {
  // console.log('CAPTCHA Response:', captchaResponse);
  this.submitUserVerification(captchaResponse);
  // Use the response token for server-side validation
}

handleError(error: any): void {
  console.error('CAPTCHA Error:', error);
}

handleExpired(): void {
  console.warn('CAPTCHA Expired');
  // Optionally reset the CAPTCHA if needed
}

}
