import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-custom-status',
  template: `
    <div class="d-flex align-items-center">
      <mat-icon [ngClass]="to.iconColor" class="me-2 ms-3">{{ to.icon }}</mat-icon>
      <span>{{ to.label }}</span>
    </div>
  `,
    styles: [`
  .text-success {
    color: green;
  }
  ::ng-deep .text-warning {
    color: #d4b033 !important;
  }
  .text-danger {
    color: red;
  }
  ::ng-deep .text-secondary {
    color: lightgray;
    background: lightgray;
    border-radius: 50%;
  }
  `],
  
})
export class FormlyCustomStatus extends FieldType {}
