import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
  constructor() {
    this.initializeGtag();
  }

 initializeGtag() {
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || [];
      window.gtag = (...args: any[]) => window.dataLayer.push(args);

      // Load the gtag.js script
      this.loadGtagScript(environment.google_tag_id);

      window.gtag('js', new Date());
      window.gtag('config', environment.google_tag_id);
    }
  }

  private loadGtagScript(gaId: string) {
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
    script.async = true;
    document.head.appendChild(script);
  }

  trackEvent(eventName: string, params: Record<string, any> = {}) {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', eventName, params);
    }
  }

  // initGtm() {
  //   const gtmId = environment.google_tag_id;
  //   if (!gtmId) {
  //     console.error('GTM ID is missing!');
  //     return;
  //   } else {
  //     console.log('-----------success');
  //   }

  //   // Add GTM <script>
  //   const script = document.createElement('script');
  //   script.async = true;
  //   script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  //   document.head.appendChild(script);

    // Add GTM <noscript>
    // const noscript = document.createElement('noscript');
    // noscript.innerHTML = `
    //   <iframe
    //     src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
    //     height="0"
    //     width="0"
    //     style="display:none;visibility:hidden"
    //   ></iframe>`;
    // document.body.appendChild(noscript);
  // }
}
