
<div class="container-fluid head_wrap">
    <div class="d-flex align-items-center ">
      <div class="logo">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- src="assets/images/logo.png" -->
            <a href="/">
              <img id="dynamicLogo" alt="" class="dynamic-logo logo-icon">
              <p id="providerWebURL"></p>
            </a>
          </div>
          <div class="col mt-3">
            <h1 id="dynamicHeader"></h1>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  <div *ngIf="isV2CaptchaRequired" 
    class="captcha-container mb-3">
    <ngx-recaptcha2
      [siteKey]="siteKey"
      (success)="handleSuccess($event)"
      (error)="handleError($event)"
      (expired)="handleExpired($event)">
    </ngx-recaptcha2>
  </div>
  <section class="mt-3" *ngIf="!isV2CaptchaRequired">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-xs-12 col-lg-12 col-md-12 mb-4">
                <mat-card class="cust_card">
                    <div class="d-flex">
                        <div class="col-12 mb-3">
                            <h2 class="text-center">{{ selectedTemplateName }}</h2>
                        </div>
                    </div>
                    <div class="mx-6">
                        <form [formGroup]="form">
                            <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options"></formly-form>  
                        </form> 
                        <div class="d-flex justify-content-center">
                            <div class="col-12 mb-3 text-center">
                              <button class="blue_button me-4 mt-3"mat-raised-button type="submit" (click)="saveForLater(continueBtnText)">{{continueBtnText}}</button>
                              <button class="me-4 mt-3" mat-raised-button color="secondary" type="submit" (click)="saveForLater('saveLater')">Save for Later</button>
                                <button class="me-4 mt-3" mat-raised-button color="secondary" type="submit" (click)="openCancelReasonPopUp()">Cancel Application</button>
                                <button class="me-4 mt-3" mat-raised-button color="secondary" type="submit" (click)="exitApplication()">Exit</button>
                                
                               
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
</section> 