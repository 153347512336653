<div *ngIf="isV2CaptchaRequired"
class="captcha-container mb-3">
<ngx-recaptcha2
  [siteKey]="siteKey"
  (success)="handleSuccess($event)"
  (error)="handleError($event)"
  (expired)="handleExpired($event)">
</ngx-recaptcha2>
</div>
<section class="mt-3" *ngIf="!isV2CaptchaRequired">
    <div class="container mt-3 mb-5">
        <div class="row ">
            <div class="col-md-12 mt-4 text-center mb-3">
                <h5>To cancel your saved application, please enter the following information:</h5>
            <div class="col-md-6 offset-md-3 mt-3">
                <mat-card class="cust_card mb-5">
                    <mat-card-content class="mb-5">
                        <form  [formGroup]="userForm">
                            <mat-form-field class="col-12">
                                <input matInput placeholder="Last Name" name="Last Name" formControlName="lastName" required>
                            </mat-form-field>
                            <div class="close-btn right-3" *ngIf="userForm.value.lastName"
                                (click)="userForm.patchValue({lastName :''});formValidation(0)">
                            </div>

                            <mat-form-field class="col-12">
                                <input matInput placeholder="Email" name="Email" formControlName="email" required>
                            </mat-form-field>
                            <div class="close-btn right-3" *ngIf="userForm.value.email"
                                (click)="userForm.patchValue({email :''});formValidation(0)">
                            </div>

                            <mat-form-field class="col-12">
                                <input matInput [type]="hide ? 'password' : 'text'" placeholder="Mother's maiden name" formControlName='motherSirName' required>
                                <span mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon class="eyeview pointer">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                  </span>
                            </mat-form-field>
                            <div class="close-btn right-3" *ngIf="userForm.value.motherSirName"
                                (click)="userForm.patchValue({motherSirName :''});formValidation(0)">
                            </div>

                            <button mat-raised-button class="blue_button float-end mb-5" (click)="formValidation(1)">Continue</button>
                            <button mat-raised-button class="float-end mb-5 me-4" (click)="goToHome()">Cancel</button>
                        </form>
                    </mat-card-content>
                </mat-card>
            </div>
            </div>
        </div>
        <!-- <div  class="col-md-12 text-center">
            <h5>Note: Your information has been saved from a previous session </h5>
        </div> -->
    </div>
</section>