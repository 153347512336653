
<div class="container-fluid head_wrap">
    <div class="d-flex align-items-center ">
      <div class="logo">
        <div class="row align-items-center">
          <div class="col-auto">
            <!-- src="assets/images/logo.png" -->
            <a href="/">
              <img id="dynamicLogo" alt="" class="dynamic-logo logo-icon">
              <p id="providerWebURL"></p>
            </a>
          </div>
          <div class="col mt-3">
            <h1 id="dynamicHeader"></h1>
          </div>
        </div>
      </div>
     
    </div>
  </div>
  <div *ngIf="isV2CaptchaRequired" 
    class="captcha-container mb-3">
    <ngx-recaptcha2
      [siteKey]="siteKey"
      (success)="handleSuccess($event)"
      (error)="handleError($event)"
      (expired)="handleExpired($event)">
    </ngx-recaptcha2>
  </div>
  <section class="mt-5" *ngIf="!isV2CaptchaRequired">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of dynamicFormList" class="col-sm-4 col-xs-12 col-lg-4 col-md-4 mb-4">
                <mat-card class="cust_card bg-aliceblue">
                    <mat-card-content>
                        <h4 class="bold text-center">
                            {{ item.name }}
                        </h4>
                        <p [innerHTML]="item.description"></p>
                        <div class="text-center">
                          <button class="blue_button justify-content-center" mat-raised-button (click)="goToApp(item.df_template_id)">Go to application</button>
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>