import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'app-edit-individual',
  templateUrl: './edit-individual.component.html',
  styleUrls: ['./edit-individual.component.css']
})
export class EditIndividualComponent implements OnInit {

  modalWidth: any;
  @Input() editModel: any;  // The selected row's data
  @Input() editFields: FormlyFieldConfig[];  // Formly JSON configuration for the fields
  @Output() editIndividual = new EventEmitter<any>();  // Emit updated data back to parent

  editIndForm = new FormGroup({});
  isCountryOriginData: boolean = false;
  ResidentialCountryID: any;
  residentialZip: any;
  indMailingZip: any;
  selectedMailingCountryID: any;
  selectedResidentialCountryID: any;

  constructor(
     @Optional() public dialogRef: MatDialogRef<any>, 
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
        private spinner: NgxSpinnerService
        , private _api: ApiService
        , private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.editFields = this.data['indJsonForm'];
    this.modalWidth = this.data['modalWidth'];
    this.editModel = this.data['editModel'];
  
    this.selectedResidentialCountryID = this.editModel['residentialCountry']
    this.selectedMailingCountryID = this.editModel['indMailingCountry']
    let countryOfOriginAutoCompleteField = this.findFieldByKey(this.editFields, 'countryOfOrigin');
    if (countryOfOriginAutoCompleteField) {
      countryOfOriginAutoCompleteField.formControl?.setValue(this.editModel['countryOfOrigin'].label, { emitEvent: false });
      this.isCountryOriginData = true;
    }

    if(localStorage.getItem('authToken')){
      this.getCountryList().subscribe(cres=>{
    });
  
  }

  this.editIndForm.valueChanges.subscribe(value => {
    const businessNature = this.getValueByKeyEdit('countryOfOrigin');
    if (!businessNature || 
      (typeof businessNature === 'string' && businessNature.trim().length === 0) || 
      (typeof businessNature === 'string' && businessNature.length <= 4)) {
      this.getCountryOfOrigin(businessNature, 'edit');
      }
    });

  this.bindFunctionsToTemplateOptions(this.editFields);
  this.modalWidth = this.data['modalWidth'];

  }

  onInMailingCountryChange(event: any) {
    this.indMailingZip = '';
    this.selectedMailingCountryID = event
      const fieldsToClear = ['mailingState', 'mailingCity', 'mailingZip'];
      fieldsToClear.forEach(key => {
        const field = this.findFieldByKey(this.editFields, key);
        if (field && field.formControl) {
          field.formControl.setValue(null, { emitEvent: false });
          field.formControl.markAsUntouched(); 
          field.formControl.markAsPristine(); 
        }
      });
  }
  functionMap = {
    onResidentialCountryChange: this.onResidentialCountryChange.bind(this),
    onInMailingCountryChange: this.onInMailingCountryChange.bind(this),
    getSelectedCountryOfOrigin: this.getSelectedCountryOfOrigin.bind(this),
    ValidateResidentialZipCode: (field: FormlyFieldConfig, event: FocusEvent) => this.ValidateResidentialZipCode(field, event),
    ValidateMailingZipCode: (field: FormlyFieldConfig, event: FocusEvent) => this.ValidateMailingZipCode(field, event),
  };
  
  bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
    fields.forEach(field => {
      if (field.fieldGroup) {
        this.bindFunctionsToTemplateOptions(field.fieldGroup);
      } else if (field.templateOptions && field.templateOptions.onClick) {
        const functionName = field.templateOptions.onClick;
        if (this.functionMap[functionName]) {
          field.templateOptions.onClick = this.functionMap[functionName];
        }
      }else if (field.templateOptions && field.templateOptions.onOptionSelected) {
          const functionName = field.templateOptions.onOptionSelected;
          if (this.functionMap[functionName]) {
            field.templateOptions.onOptionSelected = this.functionMap[functionName];
          }
      }
      // Bind blur event correctly
      if (field.templateOptions?.blurEvent) {
        const functionName = field.templateOptions.blurEvent;
        if (this.functionMap[functionName]) {
          field.templateOptions.blur = (field: FormlyFieldConfig, event: FocusEvent) => {
            if(functionName==='ValidateResidentialZipCode'){
              this.functionMap[functionName](field?.formControl?.value, true);
              
            }else if(functionName==='ValidateMailingZipCode'){
              this.functionMap[functionName](field?.formControl?.value, true);
              
            }
          }
        }
      }
     
  
       // Recursively bind functions for nested field groups
       if (field.fieldGroup) {
        this.bindFunctionsToTemplateOptions(field.fieldGroup);
      }
      if (field.templateOptions?.changeFunction) {
        const functionName = field.templateOptions.changeFunction;
        if (this.functionMap[functionName]) {
          field.templateOptions.selectionChange = (field: FormlyFieldConfig,event:Event) => {
          if(functionName==='onResidentialCountryChange'){
          if(field?.formControl?.value){
          this.functionMap[functionName](field?.formControl?.value);
          }
        }  else  if(functionName==='onInMailingCountryChange'){
          if(field?.formControl?.value){
          this.functionMap[functionName](field?.formControl?.value);
          }
        }
      }
    }
  }
    });
  }
  
  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }
  
  getValueByKey(key: string, type?) {
    return this.editIndForm.get(key)?.value;
  }
  
  
  getValueByKeyEdit(key: string, type?) {
    return this.editIndForm.get(key)?.value;
  }
  
  getCountryOfOrigin(search?, type?) {
    let countryOfOriginAutoCompleteField: any
    countryOfOriginAutoCompleteField = this.findFieldByKey(this.editFields, 'countryOfOrigin');
  
    if(search !=  undefined && search && search.length > 0){
        this.spinner.show();
        const apiData = {
         instance_id: localStorage.getItem('instance_id'),
          email: localStorage.getItem('email'),
          search: search
        };
        this._api.functionPOST('web/allCountriesList', apiData).subscribe((response) => {
          this.updateFieldOptions('countryOfOrigin', response['data'].map((country: { bt_country_id: any; name: any; }) => ({
            value: country.bt_country_id,
            label: country.name 
          })));
          this.spinner.hide();
        },
          err => {
          });
    }else {
      if (countryOfOriginAutoCompleteField) {
        countryOfOriginAutoCompleteField.formControl?.setValue('', { emitEvent: false });
        this.isCountryOriginData = false;
      }
    }
    }
  
   getSelectedCountryOfOrigin(data: any) {
      // console.log('---------data', data);
      let countryOfOriginAutoCompleteField: any;
        countryOfOriginAutoCompleteField = this.findFieldByKey(this.editFields, 'countryOfOrigin');
        this.editModel['countryOfOrigin'] = data.label;
      if (countryOfOriginAutoCompleteField && countryOfOriginAutoCompleteField.formControl?.value !== data.label) {
        countryOfOriginAutoCompleteField.formControl?.setValue(data.label, { emitEvent: false });
        this.isCountryOriginData = true;
        
      }
    }
  
  // get country list
  getCountryList(): Observable < boolean > {
    return new Observable(observer => {
    this.spinner.show();
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email')
    };
    this._api.functionPOST('web/countriesList', apiData).subscribe((res) => {
      this.updateFieldOptions('residentialCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
        value: country.bt_country_id,
        label: country.name 
      })));
      this.updateFieldOptions('indMailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
        value: country.bt_country_id,
        label: country.name 
      })));
      observer.next(true);
      observer.complete();
      this.spinner.hide();
    },
      err => {
      });
    });
  }

    onResidentialCountryChange(event: any) {
      this.residentialZip = '';
          this.selectedResidentialCountryID = event;
          const fieldsToClear = ['residentialState', 'residentialCity', 'residentialZip'];
          fieldsToClear.forEach(key => {
            const field = this.findFieldByKey(this.editFields, key);
            if (field && field.formControl) {
              field.formControl.setValue(null, { emitEvent: false });
              field.formControl.markAsUntouched(); 
              field.formControl.markAsPristine(); 
            }
          });
    }
    
    updateFieldOptions(key: string, options: any[]) {
        const field_edit = this.findFieldByKey(this.editFields, key);
        if (field_edit && field_edit.templateOptions) {
          field_edit.templateOptions.options = options;
        }
    }

    ValidateResidentialZipCode(zip_code?, type?) {
      let stateField: any;
      let cityField: any;
      let zipCodeField: any;
      const zipCode = zip_code;
      stateField = this.findFieldByKey(this.editFields, 'residentialState');
       cityField = this.findFieldByKey(this.editFields, 'residentialCity');
       zipCodeField = this.findFieldByKey(this.editFields, 'residentialZip');

      if(zipCode && this.residentialZip !== zipCode){
        this.spinner.show();
        const apiData = {
          zipcode: zipCode,
          Country: this.selectedResidentialCountryID
        };
        this._api.functionPOST('zipcode/findZip', apiData).subscribe((response) => {
          
          if(response['success'] && response['data'].length > 0){
                if (stateField) {
                  stateField.formControl?.setValue(response['data'][0]['bt_region']['name'], { emitEvent: false });
                    this.editModel['residentialState'] = response['data'][0]['bt_region']['name'];
                }
                if (cityField) {
                  cityField.formControl?.setValue(response['data'][0]['city'], {emitEvent: false});
                  this.editModel['residentialCity'] = response['data'][0]['city'];
                }
          } else {
            this.residentialZip = '';
            this._api.openSnackBar('Invalid zipcode', 'Error');
              if (stateField) {
                stateField.formControl?.setValue('', { emitEvent: false });
              }
              if (cityField) {
                cityField.formControl?.setValue('', {emitEvent: false});
              }
              if (zipCodeField) {
                zipCodeField.formControl?.setValue('', {emitEvent: false});
              }
          }
          this.spinner.hide();
        },
          err => {
          });
        }
        if(!zipCode){
          if (stateField) {
            stateField.formControl?.setValue('', { emitEvent: false });
          }
          if (cityField) {
            cityField.formControl?.setValue('', {emitEvent: false});
          }
          if (zipCodeField) {
            zipCodeField.formControl?.setValue('', {emitEvent: false});
          }
        }
        this.residentialZip = zipCode;
    }
  
    ValidateMailingZipCode(zip_code?, type?) {
      let mailingStateField: any;
      let mailingCityField: any;
      let mailingZipCodeField: any;
      const zipCode = zip_code;
     

         mailingStateField = this.findFieldByKey(this.editFields, 'mailingState');
       mailingCityField = this.findFieldByKey(this.editFields, 'mailingCity');
       mailingZipCodeField = this.findFieldByKey(this.editFields, 'mailingZip');

      if(zipCode && this.indMailingZip!=zipCode){
  
      
        this.spinner.show();
        const apiData = {
          zipcode: zipCode,
          Country: this.selectedMailingCountryID
        };
        this._api.functionPOST('zipcode/findZip', apiData).subscribe((response) => {
  
            if(response['data'].length > 0){
  
              if (mailingStateField) {
                mailingStateField.formControl?.setValue(response['data'][0]['bt_region']['name'], { emitEvent: false });
                
                
                  this.editModel['mailingState'] = response['data'][0]['bt_region']['name'];
              
              }
              if (mailingCityField) {
                mailingCityField.formControl?.setValue(response['data'][0]['city'], {emitEvent: false});
                  this.editModel['mailingCity'] = response['data'][0]['city'];
          
              }
  
            } else {
              this.indMailingZip = '';
              this._api.openSnackBar('Invalid zipcode', 'Error');
              if (mailingStateField) {
                mailingStateField.formControl?.setValue('', { emitEvent: false });
              }
              if (mailingCityField) {
                mailingCityField.formControl?.setValue('', {emitEvent: false});
              }
              if (mailingZipCodeField) {
                mailingZipCodeField.formControl?.setValue('', {emitEvent: false});
              }
            }
            this.spinner.hide();
          },
          err => {
          });
        }
        if(!zipCode){
          if (mailingStateField) {
            mailingStateField.formControl?.setValue('', { emitEvent: false });
          }
          if (mailingCityField) {
            mailingCityField.formControl?.setValue('', {emitEvent: false});
          }
          if (mailingZipCodeField) {
            mailingZipCodeField.formControl?.setValue('', {emitEvent: false});
          }
        }
        this.indMailingZip = zipCode;
            
    }
  

  close() {
    this.editIndForm.reset();
    this.dialogRef.close();
  }

  // Edit Ind Form
  onEditIndSubmit() {
    if (this.editIndForm.valid) {
      this.dialogRef.close(this.editModel);
    }else{
      this._api.openSnackBar('Please complete all required fields.', 'Error');
    }
  }

}
