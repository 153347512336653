import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, pipe, Subject } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { FormlyDynamicModalComponent } from '../shared/popups/formly-dynamic-modal/formly-dynamic-modal.component';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { form_json } from './../pureDynamicForm';
import { AddIndividualComponent } from '../shared/popups/add-individual/add-individual.component';
@Component({
  selector: 'app-dynamic-application',
  templateUrl: './dynamic-application.component.html',
  styleUrls: ['./dynamic-application.component.css']
})
export class DynamicApplicationComponent implements OnInit {
  is_mmv_required: boolean;
  form = new FormGroup({});
  model: {
    dynamicLicData?: any[];
    dynamicIndData?: any[];
    dynamicDocData?: any[];
  }  = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  selectedFormID: any;
  selectedTemplateName: any;
  indJsonForm: FormlyFieldConfig[] = [];

  countryKey: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>;
  mailingCountryKey: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>;
  docData: [];
  isV2CaptchaRequired: boolean = false;
  gettingDynamicLicData = [];
  gettingDynamicIndData = [];
  selectedTabIndex: number = 0;
  continueBtnText: string = 'Continue';
  tabValidations: boolean[] = [];
  naiceCode: any;
  masterJson: any;
  selectedLegalCountryID: any;
  selectedMailingCountryID: any;
  token: string;
  version_no: any;
  previousEIN: string | undefined = undefined;
  isFormInitialized: boolean = false;
  model_data_api: any;
  certification: any;
  legalZip: any;
  malingZip: any;
  employerOrTaxIdField: string;
  legalBusinessName: any;
  btnType: any;
  previousInstanceModel: any;

  constructor(
    private _api: ApiService
    , private spinner: NgxSpinnerService
    , private router: Router
    , private cdr: ChangeDetectorRef
    , private dialog: MatDialog
  ) {
    this.token = localStorage.getItem('authToken');
  }
  country_id=''
  call_stateapi=false;
  coutryCode: any;

  mailing_country_id=''
  mailing_call_stateapi=false;
  mailingCountry: any;

  legalcountry_id: any;
  legal_call_stateapi=false;
  leagalCountry: any;

  countryOfOrigin_id: any;
  countryOfOrigin_call_stateapi=false;
  countryOfOriginCountry: any;


  ngOnInit(): void {
    this.getDynamicFormOnApiLoad()
  }
  getDynamicFormOnApiLoad(btnTxt?) {
    if (this.token) {
      this.getDynamicForms().subscribe(dyForm => {
        this.getAllDocList();
        // Subscribe to form value changes
      // this.form.valueChanges.subscribe(value => {
      //       // console.log('--------------------------valueeee', value);

      //     if (!this.country_id && value['registeredCountry']) {
      //       this.country_id = value['registeredCountry'];
      //       this.call_stateapi = true;

      //     } else if (this.country_id && this.country_id !== value['registeredCountry']) {
      //       this.country_id = value['registeredCountry'];
      //       this.call_stateapi = true;
      //     } else {
      //       this.call_stateapi = false;
      //     }

      //     if (!this.mailing_country_id && value['mailingCountry']) {
      //       this.mailing_country_id = value['mailingCountry'];
      //       this.mailing_call_stateapi = true;

      //     } else if (this.mailing_country_id && this.mailing_country_id !== value['mailingCountry']) {
      //       this.mailing_country_id = value['mailingCountry'];
      //       this.mailing_call_stateapi = true;

      //     } else {
      //       this.mailing_call_stateapi = false;
      //     }

      //     if (!this.legalcountry_id && value['legalBusinessCountry']) {
      //       this.legalcountry_id = value['legalBusinessCountry'];
      //       this.legal_call_stateapi = true;

      //     } else if (this.legalcountry_id && this.legalcountry_id !== value['legalBusinessCountry']) {
      //       this.legalcountry_id = value['legalBusinessCountry'];
      //       this.legal_call_stateapi = true;

      //     } else {
      //       this.legal_call_stateapi = false;
      //     }

      //     if (this.model === null) {
      //       this.model = value;
      //     }
      //     // this.model = value; // Sync model with form value

      //     this.coutryCode = this.getValueByKey('registeredCountry');
      //     this.mailingCountry = this.getValueByKey('mailingCountry');
      //     this.leagalCountry = this.getValueByKey('legalBusinessCountry');
      //     this.countryOfOriginCountry = this.getValueByKey('countryOfOrigin');

      //     if (this.call_stateapi) {
      //       this.onregisteredCountryChange(this.coutryCode);
      //     }
      //     if (this.mailing_call_stateapi) {
      //       this.onMailingCountryChange(this.mailingCountry);
      //     }

      //     if (this.legal_call_stateapi) {
      //       this.onLeagalCountryChange(this.leagalCountry);
      //     }

      //     // const ein = this.getValueByKey('employerOrTaxId');
      //     // if (ein !== this.previousEIN && ein?.length === 9) {
      //     //   this.ValidateEINNumber(ein);
      //     // }
      //     });
          if ('btnTab' === btnTxt && this.getValueByKey('registeredCountry')) {
            this.loadStateOptions(this.getValueByKey('registeredCountry'))
          }
      });
    } else {
      this.exitApplication();
    }
  }
  getDynamicForms(v2token?): Observable < boolean > {
    return new Observable(observer => {
    let isV2Required;
    if(v2token){
      isV2Required = true;
    }
    this.spinner.show();
    const apiData = {
      instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      isV2CaptchaRequired: isV2Required
    };
    this._api.functionPOST('web/getApplication', apiData, v2token).subscribe((response) => {
      try{
      if(response['success'] && !response['data']['isV2CaptchaRequired']){
        this.isV2CaptchaRequired = false;
        this.fields = response['data']['form_json']['master_json'];
        this.certification  = response['data']['form_json']['certification'];
        this.indJsonForm = response['data']['form_json']['individual_json'];
        // this.fields = form_json.master_json;
        // this.indJsonForm = form_json.individual_json;
        // this.certification  = form_json['certification '];
        this.model =  response['data']['instance_model_json']||{};
        this.previousInstanceModel = JSON.stringify(this.model) ;
        this.model_data_api =   cloneDeep(response['data']['instance_model_json']);
        this.selectedTemplateName = response['data']['name'];
        this.is_mmv_required = response['data']['is_mmv_required'];
        this.version_no = response['data']['version_no'];
        this.fields.forEach(field => {
          if (field.type === 'tabs') {
            field.templateOptions.selectedIndex = this.selectedTabIndex;
          }
        });
        this.masterJson = cloneDeep(this.fields);
        if(this.model==null){
          const condition = (field: any) => {
            // Example: Hide fields with key 'dbaStatement'
            return !['dbaStatement','bankStatements','stateCannabisLicense','stateCannabisLicenseApplication', 'cannabisLicenseApplication', 'localCannabisPermit','atmAgreement'].includes(field.key);
          };
          this.fields = this.filterFields(this.masterJson, condition);
        }
        else{
          this.previousEIN = this.model['employerOrTaxId'];
          this.isFormInitialized = true;

          let filteraccordion = [];
          if(!(this.model.hasOwnProperty('fictitiousBusinessName') && this.model['fictitiousBusinessName']))
            {
              filteraccordion.push('dbaStatement');
            }
            if((this.model.hasOwnProperty('isBusinessCannabisRelated') && this.model['isBusinessCannabisRelated'] === 'no') || !this.model.hasOwnProperty('isBusinessCannabisRelated'))
            {
              filteraccordion.push('stateCannabisLicense','stateCannabisLicenseApplication', 'cannabisLicenseApplication', 'localCannabisPermit');
            }
            if((this.model.hasOwnProperty('hasAtm') && this.model['hasAtm'] === 'no') || !(this.model.hasOwnProperty('hasAtm')))
            {
              filteraccordion.push('atmAgreement');
            }
            if((this.model.hasOwnProperty('anyBankAccount') && this.model['anyBankAccount']=== 'no' && !this.model.hasOwnProperty('currentAccount')) || !this.model.hasOwnProperty('anyBankAccount'))
            {
              filteraccordion.push('bankStatements');
            }
          if(filteraccordion.length!=0){
            const condition = (field: any) => {
              return !filteraccordion.includes(field.key);
            };
            this.fields = this.filterFields(this.masterJson, condition);
          }

        }
        const individualTableField = this.findFieldByKey(this.fields, "dynamicIndData");
        if (individualTableField) {
          individualTableField.templateOptions.indForm = cloneDeep(this.indJsonForm);
        }
        if(response?.data?.instance_model_json?.dynamicLicData){
          this.gettingDynamicLicData = response['data']['instance_model_json']['dynamicLicData'];
          const dynamicTableField = this.findFieldByKey(this.fields, 'dynamicLicData');
          dynamicTableField.templateOptions.data = response['data']['instance_model_json']['dynamicLicData'];;
        }
        if(response?.data?.instance_model_json?.dynamicIndData){
          this.gettingDynamicIndData = response['data']['instance_model_json']['dynamicIndData'];
          const dynamicTableField = this.findFieldByKey(this.fields, 'dynamicIndData');
          dynamicTableField.templateOptions.data = response['data']['instance_model_json']['dynamicIndData'];;
        }

        const documentsSection =this.findDocumentsSection(this.fields)
        const keyname = this.getDocCustomAccordionKeys(documentsSection.fieldGroup);
         for (const key of keyname) {
           const docTableField = this.findFieldByKey(this.fields, `${key}DocTable`);
           if (docTableField) {
            const locationInformationAccordion = this.findFieldByKey(this.fields, key);
            if (locationInformationAccordion) {
              const locationInformationField = this.findFieldByKey(locationInformationAccordion.fieldGroup, `${key}DocTable`);
              if (locationInformationField) {
                locationInformationField.templateOptions = {
                  ...locationInformationField.templateOptions,
                  onDelete: this[locationInformationField.templateOptions.onDelete].bind(this),
                };
              }
            }
           }
        }
        this.cdr.detectChanges();
        this.bindFunctionsToTemplateOptions(this.fields);

      } else if(response['data']['isV2CaptchaRequired']) {
        this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];
      }
    }catch(err){
      console.log('getDynamicForms', err)
    }
    this.spinner.hide();
    this.getCountryList1().subscribe(cres=>{
      if(this.model?.['registeredCountry'])this.loadStateOptions(this.model['registeredCountry'])
      observer.next(true);
      observer.complete();
    })
    });
  });
  
}

  functionMap = {
    openRightSlideModalLic: this.openRightSlideModalLic.bind(this),
    openRightSlideModalInd: this.openRightSlideModalInd.bind(this),
    openRightSlideModalDoc: this.openRightSlideModalDoc.bind(this),
    openRightSlideModalDocNotApplicable: this.openRightSlideModalDocNotApplicable.bind(this),
    onDeleteRow: this.onDeleteRow.bind(this),
    onEditIndividual: this.onEditIndividual.bind(this),
    onTabChange: this.onTabChange.bind(this),
    onregisteredCountryChange: this.onregisteredCountryChange.bind(this),
    onMailingCountryChange: this.onMailingCountryChange.bind(this),
    onLeagalCountryChange: this.onLeagalCountryChange.bind(this),
    ValidateLegalZipCode: (field: FormlyFieldConfig, event: FocusEvent) => this.ValidateLegalZipCode(field, event),
    ValidateMailingZipCode: (field: FormlyFieldConfig, event: FocusEvent) => this.ValidateMailingZipCode(field, event),
    ValidateEINNumber: (ein,flag) => this.ValidateEINNumber(ein, flag),
  };

  bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
    fields.forEach(field => {
      if (field.templateOptions) {
        // Bind selectedIndexChange for tabs
        if (field.templateOptions.selectedIndexChange && field.type === 'tabs') {
          const functionName = field.templateOptions.selectedIndexChange;
          if (this.functionMap[functionName]) {
            field.templateOptions.selectedIndexChange = this.functionMap[functionName];
          }
        }
      }
        // Bind onClick event
        if (field.templateOptions.onClick) {
          const functionName = field.templateOptions.onClick;
          if (this.functionMap[functionName]) {
            field.templateOptions.onClick = this.functionMap[functionName];
          }
        }
  
        // Bind onDelete event
        if (field.templateOptions.onDelete) {
          const functionName = field.templateOptions.onDelete;
          if (this.functionMap[functionName]) {
            field.templateOptions.onDelete = this.functionMap[functionName];
          }
        }
  
        // Bind onOptionSelected event
        if (field.templateOptions.onOptionSelected) {
          const functionName = field.templateOptions.onOptionSelected;
          if (this.functionMap[functionName]) {
            field.templateOptions.onOptionSelected = this.functionMap[functionName];
          }
        }
        // Bind blur event correctly
        if (field.templateOptions.blurEvent) {
          const functionName = field.templateOptions.blurEvent;
          if (this.functionMap[functionName]) {
            field.templateOptions.blur = (field: FormlyFieldConfig, event: FocusEvent) => {
              if(functionName==='ValidateEINNumber'){
                this.functionMap[functionName](this.getValueByKey('employerOrTaxId'), true);
              }else if(functionName==='ValidateLegalZipCode'){
                this.functionMap[functionName](this.getValueByKey('legalBusinessZipCode'), true);
                
              }else if(functionName==='ValidateMailingZipCode'){
                this.functionMap[functionName](this.getValueByKey('mailingZip'), true);
                
              }
            }
          }
        }
        if (field.templateOptions.changeFunction) {
                const functionName = field.templateOptions.changeFunction;
                if (this.functionMap[functionName]) {
                  field.templateOptions.selectionChange = (field: FormlyFieldConfig,event:Event) => {
                    if(functionName==='onregisteredCountryChange'){
                    if(this.getValueByKey('registeredCountry')){
                    this.functionMap[functionName](this.getValueByKey('registeredCountry'));
                    }
                  }
               else  if(functionName==='onLeagalCountryChange'){
                  if(this.getValueByKey('legalBusinessCountry')){
                  this.functionMap[functionName](this.getValueByKey('legalBusinessCountry'));
                  }
                }  else  if(functionName==='onMailingCountryChange'){
                  if(this.getValueByKey('mailingCountry')){
                  this.functionMap[functionName](this.getValueByKey('mailingCountry'));
                  }
                }
              }
            }
          }
      // Recursively bind functions for nested field groups
      if (field.fieldGroup) {
        this.bindFunctionsToTemplateOptions(field.fieldGroup);
      }
    });
  
  }
  

  findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
    for (const field of fields) {
      if (field.key === key) {
        return field;
      }
      if (field.fieldGroup && field.fieldGroup.length) {
        const nestedField = this.findFieldByKey(field.fieldGroup, key);
        if (nestedField) {
          return nestedField;
        }
      }
    }
    return null;
  }

  getValueByKey(key: string) {
    return this.form.get(key)?.value;
  }

  filterFields(fields: any[], condition: (field: any) => boolean): any[] {
    return fields
      .map((field) => {
        // If the field has a nested fieldGroup, apply filtering recursively
        if (field.fieldGroup) {
          field.fieldGroup = this.filterFields(field.fieldGroup, condition);
        }
        return field;
      })
      .filter(condition); // Apply the condition to filter fields
  }

  onTabChange(index: number): void {
    this.btnType = this.btnType||'btnTab';
    // console.log('-------btnType', this.btnType)
    if(this.getValueByKey('legalBusinessName') && this.btnType==='btnTab'){
      // console.log(JSON.stringify(this.model))
      // console.log(JSON.stringify(this.model_data_api))
      if(JSON.stringify(this.model)!== JSON.stringify(this.model_data_api)){
        if(this.selectedTabIndex!==2){this.saveForLater('btnTab')};
      }
    }
    this.selectedTabIndex = index;
    if(this.selectedTabIndex === 2){
      this.continueBtnText = 'Certify';
    } else {
      this.continueBtnText = 'Continue';
    }
    this.btnType = "btnTab";
  }

  onDeleteRow(isDeleted: boolean) {
    if(isDeleted){
      this.getAllDocList();
    }
  }

  openCancelReasonPopUp(){
    const dialogRef = this.dialog.open(BasicPopupComponent, {
      maxWidth: '600px'
      , data: {
        pageTitle: "Reason",
        pageType: 'cancel_application',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.cancelApplication(result);
      }
    });
  }

  cancelApplication(reason: any){
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      comment: reason,
      latitude: localStorage.getItem('latitude'),
      longitude:  localStorage.getItem('longitude')
    };
    this._api.functionPOST('web/cancelApplication', apiData).subscribe((response) => {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    });
  }

  exitApplication(){
    const apiData = {
    };
    this._api.functionPOST('web/removeSession', apiData).subscribe((response) => {
      this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    });

  }

  // get country list
  getCountryList1(): Observable < boolean > {
    return new Observable(observer => {
      const apiData = {
        instance_id: localStorage.getItem('instance_id'),
        email: localStorage.getItem('email'),
      };
      this._api.functionPOST('web/countriesList', apiData).subscribe((res) => {
        this.updateFieldOptions('registeredCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
          value: country.bt_country_id,
          label: country.name
        })));
        this.updateFieldOptions('mailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
          value: country.bt_country_id,
          label: country.name
        })));
        this.updateFieldOptions('legalBusinessCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
          value: country.bt_country_id,
          label: country.name
        })));
        observer.next(true);
        observer.complete();
      },
        err => {
          observer.next(true);
          observer.complete();
        });
    });
  }
//   getCountryList() {
//     this.spinner.show();
//     const apiData = {
//       instance_id: localStorage.getItem('instance_id'),
//       email: localStorage.getItem('email'),
//     };
//     this._api.functionPOST('web/countriesList', apiData).subscribe((res) => {
//       this.updateFieldOptions('registeredCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
//         value: country.bt_country_id,
//         label: country.name
//       })));
//       this.updateFieldOptions('mailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
//         value: country.bt_country_id,
//         label: country.name
//       })));
//       this.updateFieldOptions('legalBusinessCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
//         value: country.bt_country_id,
//         label: country.name
//       })));

//       this.spinner.hide();
//     },
//       err => {
//       });
// }

  onregisteredCountryChange(event: any) {
    this.loadStateOptions(event);
  }

  loadStateOptions(countryCode: string) {
    this.spinner.show();
    const apiData = {
      instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      country_id: countryCode
    };
    this._api.functionPOST('web/statesList', apiData).subscribe((data: { [x: string]: any[]; }) => {
      this.updateFieldOptions('registeredStateOrProvince', data['data'].map((country: { bt_region_id: any; name: any; }) => ({
        value: country.bt_region_id,
        label: country.name
      })));
      this.spinner.hide();
    });
  }

  updateFieldOptions(key: string, options: any[]) {
    const field = this.findFieldByKey(this.fields, key);
    if (field && field.templateOptions) {
      field.templateOptions.options = options;
    }
  }

  onLeagalCountryChange(event: any) {
    if(!this.selectedLegalCountryID )
      {
        this.selectedLegalCountryID = event;
      }else {
        this.selectedLegalCountryID = event;
      }
      const fieldsToClear = ['legalBusinessState', 'legalBusinessCity', 'legalBusinessZipCode'];
      fieldsToClear.forEach(key => {
        const field = this.findFieldByKey(this.fields, key);
        if (field && field.formControl) {
          field.formControl.setValue(null, { emitEvent: false });
          field.formControl.markAsUntouched(); 
          field.formControl.markAsPristine(); 
        }
      });
  }

  onMailingCountryChange(event: any) {
    if(!this.selectedMailingCountryID )
      {
        this.selectedMailingCountryID = event;
      }else {
        this.selectedMailingCountryID = event;
      }
      const fieldsToClear = ['mailingState', 'mailingCity', 'mailingZip'];
      fieldsToClear.forEach(key => {
        const field = this.findFieldByKey(this.fields, key);
        if (field && field.formControl) {
          field.formControl.setValue(null, { emitEvent: false });
          field.formControl.markAsUntouched(); 
          field.formControl.markAsPristine(); 
        }
      });
  }

  ValidateEINNumber(ein: string | undefined,flag=false) {
    const employerOrTaxIdField = this.findFieldByKey(this.fields, 'employerOrTaxId');
    if(!this.model['legalBusinessName']?.trim() &&  ein &&ein?.length === 9){
      this._api.openSnackBar('Legal business name is required', 'Error');
      return;
    }
    if( this.employerOrTaxIdField!==ein || (flag && this.legalBusinessName !== this.model['legalBusinessName'] && ein &&ein?.length === 9)){
      if (!this.isFormInitialized || ein && ein.length > 0) {
        this.employerOrTaxIdField  = ein;
        this.legalBusinessName = this.model['legalBusinessName'];
        this.spinner.show();
        const apiData = {
         instance_id: localStorage.getItem('instance_id'),
          email: localStorage.getItem('email'),
          tin: ein,
          name: this.model['legalBusinessName'],
          // name: "StandardC"
        };
        this._api.functionPOST('web/validateEINNumber', apiData).subscribe((response) => {
          if(response['success']){
            this.previousEIN = ein;
            this.isFormInitialized = true;
            if(response['data']['success']){
              this._api.openSnackBar(response['data']['success'], 'Success');
            } else if(response['data']['warning']){
              this._api.openSnackBar(response['data']['warning'], 'Warning');
            } else {
              this._api.openSnackBar(response['data'] , 'Error');
            }
          } else {
            this._api.openSnackBar(response['data'], 'Error');
          }
          
          this.spinner.hide();
          },
            err => {
            });
  }
}
}

  
ValidateLegalZipCode(field, event?: FocusEvent) {
  const zipCode = field;
    const stateField = this.findFieldByKey(this.fields, 'legalBusinessState');
    const cityField = this.findFieldByKey(this.fields, 'legalBusinessCity');
    const zipCodeField = this.findFieldByKey(this.fields, 'legalBusinessZipCode');
    const country_id = this.selectedLegalCountryID || this.model['legalBusinessCountry']
    if(zipCode && this.legalZip!=zipCode ){
        this.spinner.show();
        const apiData = {
          zipcode: zipCode,
          Country: country_id
        };
        this._api.functionPOST('zipcode/findZip', apiData).subscribe((response) => {
          if(response['success'] && response['data'].length > 0){
            this.model['legalBusinessZipCodeID'] = response['data'][0]['bt_zipcodes_id'];
                if (stateField) {
                  stateField.formControl?.setValue(response['data'][0]['bt_region']['name'], { emitEvent: false });
                  this.model['legalBusinessState'] = response['data'][0]['bt_region']['name'];
                }
                if (cityField) {
                  cityField.formControl?.setValue(response['data'][0]['city'], {emitEvent: false});
                  this.model['legalBusinessCity'] = response['data'][0]['city'];
                }
          } else {
                this.legalZip = '';
                this._api.openSnackBar('Invalid zipcode', 'Error');
                  if (stateField) {
                    stateField.formControl?.setValue('', { emitEvent: false });
                  }
                  if (cityField) {
                    cityField.formControl?.setValue('', {emitEvent: false});
                  }
                  if (zipCodeField) {
                    zipCodeField.formControl?.setValue('', {emitEvent: false});
                  }
                  
          }
          this.spinner.hide();
          },
        err => {
        });
      }
      if(!zipCode){
        if (stateField) {
          stateField.formControl?.setValue('', { emitEvent: false });
        }
        if (cityField) {
          cityField.formControl?.setValue('', {emitEvent: false});
        }
        if (zipCodeField) {
          zipCodeField.formControl?.setValue('', {emitEvent: false});
        }
      }
      this.legalZip = zipCode;
}

ValidateMailingZipCode(field, event: FocusEvent) {
  const zipCode = field;
  const mailingStateField = this.findFieldByKey(this.fields, 'mailingState');
  const mailingCityField = this.findFieldByKey(this.fields, 'mailingCity');
  const mailingZipCodeField = this.findFieldByKey(this.fields, 'mailingZip');
  const country_id = this.selectedMailingCountryID || this.model['mailingCountry']
  if(zipCode && this.malingZip!=zipCode){
        this.spinner.show();
        const apiData = {
          zipcode: zipCode,
          Country: country_id
        };
        this._api.functionPOST('zipcode/findZip', apiData).subscribe((response) => {
            
            if(response['success'] && response['data'].length > 0){
              this.model['mailingZipCodeID'] = response['data'][0]['bt_zipcodes_id'];
              if (mailingStateField) {
                mailingStateField.formControl?.setValue(response['data'][0]['bt_region']['name'], { emitEvent: false });
                this.model['mailingState'] = response['data'][0]['bt_region']['name'];
              }
              if (mailingCityField) {
                mailingCityField.formControl?.setValue(response['data'][0]['city'], {emitEvent: false});
                this.model['mailingCity'] = response['data'][0]['city'];
              }

            } else {
              this.malingZip = '';
              this._api.openSnackBar('Invalid zipcode', 'Error');
              if (mailingStateField) {
                mailingStateField.formControl?.setValue('', { emitEvent: false });
              }
              if (mailingCityField) {
                mailingCityField.formControl?.setValue('', {emitEvent: false});
              }
              if (mailingZipCodeField) {
                mailingZipCodeField.formControl?.setValue('', {emitEvent: false});
              }
            }
            this.spinner.hide();
          },
          err => {
          });
        }if(!zipCode){
          if (mailingStateField) {
            mailingStateField.formControl?.setValue('', { emitEvent: false });
          }
          if (mailingCityField) {
            mailingCityField.formControl?.setValue('', {emitEvent: false});
          }
          if (mailingZipCodeField) {
            mailingZipCodeField.formControl?.setValue('', {emitEvent: false});
          }
        }
        this.malingZip = zipCode;
}

openRightSlideModalLic(event: Event) {
    const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'lic_tab', modalWidth:'100'},
      disableClose: true,
    });

    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
          this.appendDataToTable(result);
      }
    });
  }

appendDataToTable(result?: any) {
  const dynamicTableField = this.findFieldByKey(this.fields, 'dynamicLicData');

  if(dynamicTableField && this.gettingDynamicLicData.length === 0) {
    if (!this.model.dynamicLicData) {
      this.model.dynamicLicData = [];
    }
    this.model.dynamicLicData = [...this.model.dynamicLicData, ...result];
  }
  if(dynamicTableField && result && this.gettingDynamicLicData.length > 0){
    this.model.dynamicLicData = [...this.gettingDynamicLicData, ...result]
  }
    dynamicTableField.templateOptions.data = this.model.dynamicLicData;
  this.cdr.detectChanges();
}

openRightSlideModalInd() {
  const dialogRef = this.dialog.open(AddIndividualComponent, {
      width: '0px',
      position: { right: '0' },
      panelClass: 'slide-in-right',
      data: { tabType: 'key_person_tab', indJsonForm: cloneDeep(this.indJsonForm), modalWidth:'100', individualDetails: this.model.dynamicIndData},
      disableClose: true,
    });
    dialogRef.afterOpened().subscribe(() => {
      const dialogElement = document.querySelector('.slide-in-right .modal-container');
      if (dialogElement) {
        dialogElement.classList.add('open');
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.appendDataToIndTable(result);
      }
    });
}

appendDataToIndTable(result: any) {
  const individualTableField = this.findFieldByKey(this.fields, 'dynamicIndData');
  if (individualTableField) {
    const currentData = individualTableField.templateOptions?.['data'] || [];
    this.model.dynamicIndData = [
      ...currentData,
      result
    ];
    if (individualTableField.templateOptions) {
      individualTableField.templateOptions['data'] = this.model.dynamicIndData || [];
  }
  }
}

onEditIndividual(updatedResult: any, index: any){
  const individualTableField = this.findFieldByKey(this.fields, 'dynamicIndData');
  if (individualTableField) {
    const currentData = individualTableField.templateOptions?.['data'] || [];
      const rowIndex = index;
      if (rowIndex >= 0 && rowIndex < currentData.length) {
        currentData[rowIndex] = updatedResult;
        this.model.dynamicIndData = [...currentData];
        if (individualTableField.templateOptions) {
          individualTableField.templateOptions['data'] = this.model.dynamicIndData || [];
      }
      } else {
        console.error('Invalid row index:', rowIndex);
      }
  }
}

openRightSlideModalDoc(field: any, event: Event): void {
  const docTabTitle = field.templateOptions.valueToPass;
  const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
    width: '0px',
    position: { right: '0' },
    panelClass: 'slide-in-right',
    data: { tabType: 'document_tab', docType: docTabTitle, isApplicable: true},
    disableClose: true,
  });

  dialogRef.afterOpened().subscribe(() => {
    const dialogElement = document.querySelector('.slide-in-right .modal-container');
    if (dialogElement) {
      dialogElement.classList.add('open');
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result){
      this.getAllDocList();
    }
  });
}

openRightSlideModalDocNotApplicable(field: any, event: Event): void {
  const docTabTitle = field.templateOptions.valueToPass;
  const dialogRef = this.dialog.open(FormlyDynamicModalComponent, {
    width: '0px',
    position: { right: '0' },
    panelClass: 'slide-in-right',
    data: { tabType: 'document_tab', docType: docTabTitle, isApplicable: false},
    disableClose: true,
  });

  dialogRef.afterOpened().subscribe(() => {
    const dialogElement = document.querySelector('.slide-in-right .modal-container');
    if (dialogElement) {
      dialogElement.classList.add('open');
    }
  });

  dialogRef.afterClosed().subscribe(result => {
    this.getAllDocList();
  });
}

// 0: 'Pending',
// 1: 'Uploaded',
// 2: 'Not Applicable',

findDocumentsSection(masterJson: FormlyFieldConfig[]): FormlyFieldConfig | undefined {
  return masterJson[0].fieldGroup.find(
    (item) => item.templateOptions?.label === 'Documents'
  );
}

getDocCustomAccordionKeys(fieldGroup: FormlyFieldConfig[]):string []{
  const keys: string[] = [];
  for (const item of fieldGroup) {
    if (item.type === 'custom-accordion' && item.fieldGroup) {
      for(const accordion of item.fieldGroup){
        let key  = accordion['key'];
        if (typeof key === 'string') {
          keys.push(key);
      }
    }
    }
    else if (item.fieldGroup) {
      keys.push(...this.getDocCustomAccordionKeys(item.fieldGroup));
    }
  }
  return keys
}

getAllDocList(){
  this.spinner.show();
    const apiData = {
      instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
    };
    this._api.functionPOST('web/getAllDocumentByInstanceId', apiData).subscribe((response) => {
      try{
      if(response['success']){
        this.docData = response['data'];
       if(this.docData){
        const documentsSection =this.findDocumentsSection(this.fields)
       const keyname = this.getDocCustomAccordionKeys(documentsSection.fieldGroup);
        for (const key of keyname) {
          const docTableField = this.findFieldByKey(this.fields, `${key}DocTable`);
          if ((docTableField && this.docData[key]) || (docTableField && !this.docData[key])) {
            const statusField = this.findFieldByKey(this.fields, `${key}Status`);
            if (statusField && this.docData[key]) {
              statusField.templateOptions.value =
                this.docData[key]["upload_status"] === 1
                  ? "Uploaded"
                  : this.docData[key]["upload_status"] === 2
                  ? "Not Applicable"
                  : "";
                  statusField.templateOptions.isDocAttached = this.docData[key]["v_df_document_attaches"].length;
            }
            else{
              statusField.templateOptions.value ='';
            }

            const commentField = this.findFieldByKey(this.fields, `${key}Comments`);
            if (commentField && this.docData[key]) {
              commentField.templateOptions.value = this.docData[key]["doc_comment"];
              commentField.templateOptions.isDocAttached = this.docData[key]["v_df_document_attaches"].length;
            }
            else{
              commentField.templateOptions.value ='';
            }

            const updatedDateField = this.findFieldByKey(this.fields, `${key}Date`);
            if (updatedDateField && this.docData[key]) {
              updatedDateField.templateOptions.value = this.docData[key]["updated"];
              updatedDateField.templateOptions.isDocAttached = this.docData[key]["v_df_document_attaches"].length;
            }
            else{
              updatedDateField.templateOptions.value ='';
            }

            if(this.docData[key] && this.docData[key]["v_df_document_attaches"]){
              docTableField.templateOptions.data = this.docData[key]["v_df_document_attaches"];
              if(this.model.hasOwnProperty('dynamicDocData')){
                if (!this.model.dynamicDocData.some(data => JSON.stringify(data) === JSON.stringify(this.docData[key]))) {
                  this.model.dynamicDocData.push(this.docData[key]);
                }
              }else{
                  this.model['dynamicDocData']=[this.docData[key]];
              }

            }else{
              docTableField.templateOptions.data=[];
              if (this.model.dynamicDocData && this.docData[key]) {
                if (!this.model.dynamicDocData.some(data => JSON.stringify(data) === JSON.stringify(this.docData[key]))) {
                  this.model.dynamicDocData.push(this.docData[key]);
                }
              } else {
                console.warn('Key not present in the response or dynamicDocData is missing.');
              }

              // if (!this.model.dynamicDocData.some(data => JSON.stringify(data) === JSON.stringify(this.docData[key]))) {
              //   this.model.dynamicDocData.push(this.docData[key]);
              // }
            }
      
          }
        }
      }
      } else {
        this.model.dynamicDocData = [];
      }
      this.cdr.detectChanges();
    }catch(eerr){
      console.log('getAllDocList : ',eerr)
    }
      this.spinner.hide();
    });

}

getFieldKeysForTab(index: number): string[] {
  const tab = this.fields[0].fieldGroup[index]; // Get the tab at the specified index
  if (tab) {
    return tab.fieldGroup[0].fieldGroup.map((field) => field.key as string).filter((key) => !!key); // Extract keys, filter out undefined/null
  }
  return [];
}

getAllRequiredFieldKeys(fields: any[], prefix: string = ''): string[] {
  const requiredKeys: string[] = [];

  fields.forEach((field) => {
    // If the field has a key and is marked as required
    if (field.key && field.templateOptions?.required) {
      requiredKeys.push(prefix ? `${prefix}.${field.key}` : field.key);
    }

    // If the field contains nested fields (fieldGroup)
    if (field.fieldGroup) {
      requiredKeys.push(
        ...this.getAllRequiredFieldKeys(
          field.fieldGroup,
          field.key ? (prefix ? `${prefix}.${field.key}` : field.key) : prefix
        )
      );
    }
  });

  return requiredKeys;
}

  continueSave(btnType?) {
    let nextIndex = this.selectedTabIndex + 1;
    if (this.selectedTabIndex === 0 || this.selectedTabIndex === 1) {

      this.fields.forEach(field => {
        if (field.type === 'tabs') {
          field.templateOptions.selectedIndex = nextIndex;
        }
      });

      this.selectedTabIndex = nextIndex;
      if (this.selectedTabIndex === 2) {
        this.continueBtnText = 'Certify';
      } else {
        this.continueBtnText = 'Continue';
      }
    }
  }

  saveForLater(btnType?){
   this.validateBusinessName(btnType);
  }

  validateBusinessName(btnType?){
    const legalBusinessName = this.getValueByKey('legalBusinessName');
    if(!legalBusinessName){
      this._api.openSnackBar('Enter Business Name', 'Error');
      return
    }
    this.spinner.show();
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      business_name: legalBusinessName
    };
    this._api.functionPOST('web/validateBusinessName', apiData).subscribe((response) => {
      if(response['success']){
          if (this.is_mmv_required){
            // true = show modal
            const dialogRef = this.dialog.open(BasicPopupComponent, {
              maxWidth: '600px'
              , data: {
                pageTitle: "Save Progress",
                pageType: 'mother_maiden_name',
              }
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result) {
                if(['Continue','saveLater','btnTab'].includes(btnType))
                  this.partiallySave(btnType, result);
              }
              if(btnType === 'Certify'){
                // this.finalSubmission();
                this.getCertifySignaturePopup();
              }
            });
          }else{
            // call save later function
            this.partiallySave(btnType);
          }
      } else{
        this._api.openSnackBar(response['data'], 'Error');
      }
     
    });
    this.spinner.hide();
  }

  saveLaterFormData(btnType?, mother_maiden_name?: any){
    if(['Continue','saveLater','btnTab'].includes(btnType)){
        this.partiallySave(btnType, mother_maiden_name);
      } else if(btnType === 'Certify'){
        this.getCertifySignaturePopup();
      }
  }

  partiallySave(btnType?, mother_maiden_name?: any){
    const legalBusinessName = this.getValueByKey('legalBusinessName');
    this.spinner.show();

    if(['Continue','saveLater','btnTab'].includes(btnType)){

      if(this.selectedTabIndex === 0 && btnType ==='Continue'){
        const allTabFields = this.fields.map(tab => tab.fieldGroup);
        const requiredFieldKeys = this.getAllRequiredFieldKeys(allTabFields[0]);
          const controls = requiredFieldKeys.map((field) => this.form.get(field as string));
          
          this.tabValidations[this.selectedTabIndex] = controls.every((control) => control?.valid);
          if (!this.tabValidations[this.selectedTabIndex]) {
            this._api.openSnackBar('Please complete all required fields', 'Error');
            controls.forEach((control) => control?.markAsTouched());
            this.spinner.hide();
            return
          }
        const activeCannabisLicense = this.model['activeCannabisLicense'];
        const isLicenseData =  this.model.dynamicLicData;
        if(activeCannabisLicense==='yes'){
          if(!isLicenseData || isLicenseData.length===0){
            this._api.openSnackBar('Add License. Active cannabis license(s) is selectes Yes.', 'Error');
            this.spinner.hide();
            return;
          }
        }
      }


      if(btnType === 'Continue' && this.selectedTabIndex === 1){
        const activebeneficialOwners = this.model['beneficialOwners'];
        const isownership =  this.model.dynamicIndData;
        if(!this.validateIndividualUser()){
           this.spinner.hide();
          return;
        }
    }

      const apiData = {
       instance_id: localStorage.getItem('instance_id'),
        email: localStorage.getItem('email'),
        mother_maiden_name: mother_maiden_name,
        application_data: this.fields,
        instance_model: this.model,
        business_name: legalBusinessName,
        latitude: localStorage.getItem('latitude'),
        longitude:  localStorage.getItem('longitude')
      };
      if(this.previousInstanceModel=== JSON.stringify( this.model) && btnType === 'Continue'){
        this.continueSave(this.btnType);
        this.spinner.hide();
        return
      }
      this.previousInstanceModel = JSON.stringify(this.model) ;

      this._api.functionPOST('web/saveApplication', apiData).subscribe((response) => {
        if(response['success']){
          this.is_mmv_required = response.data.is_mmv_required;
  
          if(btnType === 'saveLater'){
            const dialogRef = this.dialog.open(BasicPopupComponent, {
              maxWidth: '600px'
              , data: {
                pageTitle: "Next Steps",
                pageType: 'saveApplication',
                message: response['data']['message']
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if(result){
                this.router.navigate(['/']);
              }
            });
  
          } else if(btnType === 'Continue'){
            this.btnType='Continue';
            this.continueSave(this.btnType);
            this.getDynamicFormOnApiLoad();
          }else if ('btnTab' === btnType){
            this.getDynamicFormOnApiLoad('btnTab');
          }
          // this._api.openSnackBar(response['data']['message'], 'Success')
        }
        this.spinner.hide();
      });

    }else if(btnType === 'Certify'){
      this.getCertifySignaturePopup();
      this.spinner.hide();
    }
  }

  getAllRequiredFieldKeysForSubmit(fields: FormlyFieldConfig[]): string[] {
    return fields
      .filter(field => field.templateOptions?.required)
      .map(field => field.key as string);
  }

  getCertifySignaturePopup(){

    const documentsSection =this.findDocumentsSection(this.fields)
    const keyname = this.getDocCustomAccordionKeys(documentsSection.fieldGroup);
    
    const allTabFields = this.fields.map(tab => tab.fieldGroup).flat();
    const requiredFieldKeys = this.getAllRequiredFieldKeys(allTabFields);
      const controls = requiredFieldKeys.map((field) => this.form.get(field as string));
      
      this.tabValidations[this.selectedTabIndex] = controls.every((control) => control?.valid);
      if (!this.tabValidations[this.selectedTabIndex]) {
        this._api.openSnackBar('Please complete all required fields', 'Error');
        controls.forEach((control) => control?.markAsTouched());
        return
      }

      const activebeneficialOwners = this.model['beneficialOwners'];
      const isownership =  this.model.dynamicIndData;
      if(!this.validateIndividualUser()){
        return;
      }
      
     for(const key of keyname){
      if(requiredFieldKeys.includes(key)){
        if(!this.docData[key]){
          this._api.openSnackBar('Mandatory Document is required', 'Error');
          return;
        }
      }
     }

     const dialogRef = this.dialog.open(BasicPopupComponent, {
      maxWidth: '600px'
      , data: {
        pageTitle: "Certification",
        pageType: 'certification_signature',
        certification : this.certification 
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.finalSubmission();
      }
    });

  }
  validateIndividualUser(){
    let onwer_count=0,controller_count=0;
      if (this.model?.dynamicIndData?.length>0) {
        for (const user of this.model.dynamicIndData) {
          if (user.firstName?.trim().length <= 2) {
             this._api.openSnackBar('First name must be at least 2 characters long.', 'Error');
          }
          if (user.lastName?.trim().length <= 2) {
               this._api.openSnackBar(`Last name must be at least 2 characters long.`, 'Error');
          }
          if (user?.ownership25Percent?.toLowerCase() === 'yes') {
            onwer_count++;
          }
          if(user?.responsibility?.toLowerCase() === 'yes'){
            controller_count++;
          }
        }
      }
      if(this.model['beneficialOwners']==='yes'){
        if(onwer_count===0){
          this._api.openSnackBar('Since the business has an owner with at least 25% ownership, there should be at least one owner listed under individuals.', 'Error');
          return false;
        }
      }
      if(this.model['businesscontrolperson']==='yes'){
        if(controller_count===0){
          this._api.openSnackBar('Since the business has a control person, there should be at least one controlling manager listed under individuals.', 'Error');
          return false;
        }
      }
      if(!this.model?.dynamicIndData?.length){
        this._api.openSnackBar('There must be at least one owner or controlling manager listed under individuals', 'Error');
        return false;
      }
      return true;
  }
  finalSubmission(){
    const legalBusinessName = this.getValueByKey('legalBusinessName');
    this.spinner.show();
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email'),
      application_data: this.fields,
      instance_model: this.model,
      business_name: legalBusinessName,
      latitude: localStorage.getItem('latitude'),
      longitude:  localStorage.getItem('longitude')
    };
    this._api.functionPOST('web/submitApplication', apiData).subscribe((response) => {
      if(response['success']){
        this._api.openSnackBar(response['data'], 'Success')
        this.router.navigate(['/']);
    }
  });
}

}

function cloneDeep<T>(value: T): T {
  // Check if the value is null or not an object/array
  if (value === null || typeof value !== "object") {
    return value;
  }

  // Handle Arrays
  if (Array.isArray(value)) {
    return value.map(item => cloneDeep(item)) as unknown as T;
  }

  // Handle Objects
  const result: Record<string, any> = {};
  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      result[key] = cloneDeep((value as Record<string, any>)[key]);
    }
  }
  return result as T;
}
