export const environment = {
  production: true,
  name: 'development',
  BACKEND: 'https://api-am-dev.standardc.io/',
  FRONTEND: 'https://am-dev.standardc.io/',
  //IDme Client-id
  client_id: '655e54c855a52bca444898f7ed96e6e9',
  idme_scope: "identity_dev",
  idme_url: 'https://api.idmelabs.com',
  idme_redirect_uri: 'https://am-dev.standardc.io/verification',
  reCaptchaSiteKey:'6Lfz5lMqAAAAAHmYj_tJdy-QWP7iVSh1TiA6iOhy',
  reCaptcha2SiteKey:'6Ldl9k4qAAAAACKFYDby2-hntDttFQIrFISIxGt_',
  google_tag_id: 'G-Y1BQNDH6SN',
  help_doc: 'https://stdc-dev-static-s3.s3.us-west-1.amazonaws.com/help/How-to-Enable-Location-Access.pdf'
};