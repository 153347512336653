import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, zip } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { CaptureImageFromCameraComponent } from '../capture-image-from-camera/capture-image-from-camera.component';
import { Observable, pipe, Subject } from "rxjs";

@Component({
  selector: 'app-formly-dynamic-modal',
  templateUrl: './formly-dynamic-modal.component.html',
  styleUrls: ['./formly-dynamic-modal.component.css']
})
export class FormlyDynamicModalComponent implements OnInit {
  tabType = '';
  searchinput: FormGroup;
  searchInput=''; 
  license_data: any;
  sortcolumn = '';
  sortorder = '';   
  selectedUsers: any[] = [];
  clickedPhoto: File;

  page = {
    pageSizeOptions: [10, 25, 50, 100]
    , limit: 25
    , pageSize: 25
    , index: 0
  };
  displayedColumns: string[] = [
    'Status'
    , 'Business Legal Name'
    , 'License Type'
    , 'Action'
  ];

  form = new FormGroup({});
  model: {
    dynamicData?: any[];
  }  = {};
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [];
  modalWidth: any;
  coutryCode: any;
  mailingCountry: any;
  countryKey: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>;
  mailingCountryKey: FormlyFieldConfig<import("@ngx-formly/core").FormlyFieldProps & { [additionalProperties: string]: any; }>;
  country_id=''
  call_stateapi=false

  mailing_country_id=''
  mailing_call_stateapi=false

  docForm: FormGroup;
  selectedFile;
  AttachmentsName = '';
  apiImage: any;
  docTitle: any;
  formSubmitted = false;
  selectedFiles: File[] = [];
  apiImages: File[] = [];
  isApplicable: false;
  AttachFiles = [];
  attachNames = [];
  max_file_count=0;
  supportedFile={
   maxSize:'',
   fileSize:0,
   type:''
 };
 supportedDocType = '';
 supporetsFiles = '';
 supportMaxFileSize = '';
 @ViewChild('attachInput') attachInputRef: any;
  countryOfOriginCountry: any;
  selectedResidentialCountryID: any;
  selectedMailingCountryID: any;
  capturedPhotoFromModal: any;
  timestamp: any;
  isCountryOriginData: boolean = false;
  ResidentialCountryID: any;
  residentialZip: any;
  indMailingZip: any;
 
  constructor(
    @Optional() public dialogRef: MatDialogRef<any>, 
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
    private formBuilder: FormBuilder
    , private spinner: NgxSpinnerService
    , private _api: ApiService
    , private cookieService: CookieService
    , private _utils: UtilitiesService
    , private dialog: MatDialog
  ) {
    this.searchinput = this.formBuilder.group({
      search_field: new FormControl(''),
  });
  this.docForm = this.formBuilder.group({
    appDoc: ['upload_from_device'],
    comments: new FormControl(''),
    uploadDocuments: [null],
});

  }

  ngOnInit(): void {
    this.tabType = this.data['tabType']

    if(this.tabType === 'key_person_tab') {
      this.fields = this.data['indJsonForm'];
      if(localStorage.getItem('authToken')){
        this.getCountryList().subscribe(cres=>{
      });
    }
    
      this.bindFunctionsToTemplateOptions(this.fields);
      this.modalWidth = this.data['modalWidth'];
    }

    if(this.tabType === 'document_tab') {
      // console.log('-------------this.data[do', this.data['docType'])
      this.getSupportedFiles();
      this.modalWidth = this.data['modalWidth'];
      this.docTitle = this.data['docType'];
      this.isApplicable = this.data['isApplicable'];

      // console.log('----------this.isApplicable', this.isApplicable);
      if(this.isApplicable){
        // Conditionally required 'uploadDocuments' based on 'appDoc' value
        this.docForm.get('appDoc')?.valueChanges.subscribe((value) => {
          if (value === 'upload_from_device' || value === 'upload_from_mobile') {
              this.docForm.get('uploadDocuments')?.setValidators(Validators.required);
          }else {
              this.docForm.reset();
              this.docForm.get('uploadDocuments')?.clearValidators();
          }
              this.docForm.get('uploadDocuments')?.updateValueAndValidity();
        });
      }else{
        this.docForm.reset();
        this.docForm.get('uploadDocuments')?.clearValidators();
        this.docForm.get('appDoc')?.clearValidators();
        this.docForm.get('uploadDocuments')?.updateValueAndValidity();
        this.docForm.get('appDoc')?.updateValueAndValidity();
      }
    
    }

    if(this.tabType === 'lic_tab') {
      this.modalWidth = this.data['modalWidth'];
    }
}

onInMailingCountryChange(event: any) {
  this.indMailingZip = '';
  this.selectedMailingCountryID = event;
    const fieldsToClear = ['mailingState', 'mailingCity', 'mailingZip'];
    fieldsToClear.forEach(key => {
      const field = this.findFieldByKey(this.fields, key);
      if (field && field.formControl) {
        field.formControl.setValue(null, { emitEvent: false });
        field.formControl.markAsUntouched(); 
        field.formControl.markAsPristine(); 
      }
    });
}
functionMap = {
  onResidentialCountryChange: this.onResidentialCountryChange.bind(this),
  onInMailingCountryChange: this.onInMailingCountryChange.bind(this),
  getSelectedCountryOfOrigin: this.getSelectedCountryOfOrigin.bind(this),
  ValidateResidentialZipCode: (field: FormlyFieldConfig, event: FocusEvent) => this.ValidateResidentialZipCode(field, event),
  ValidateMailingZipCode: (field: FormlyFieldConfig, event: FocusEvent) => this.ValidateMailingZipCode(field, event),
};

bindFunctionsToTemplateOptions(fields: FormlyFieldConfig[]) {
  fields.forEach(field => {
    if (field.fieldGroup) {
      this.bindFunctionsToTemplateOptions(field.fieldGroup);
    } else if (field.templateOptions && field.templateOptions.onClick) {
      const functionName = field.templateOptions.onClick;
      if (this.functionMap[functionName]) {
        field.templateOptions.onClick = this.functionMap[functionName];
      }
    }else if (field.templateOptions && field.templateOptions.onOptionSelected) {
        const functionName = field.templateOptions.onOptionSelected;
        if (this.functionMap[functionName]) {
          field.templateOptions.onOptionSelected = this.functionMap[functionName];
        }
    }
    // Bind blur event correctly
    if (field.templateOptions?.blurEvent) {
      const functionName = field.templateOptions.blurEvent;
      if (this.functionMap[functionName]) {
        field.templateOptions.blur = (field: FormlyFieldConfig, event: FocusEvent) => {
          if(functionName==='ValidateResidentialZipCode'){
            this.functionMap[functionName](field?.formControl?.value, true);
            
          }else if(functionName==='ValidateMailingZipCode'){
            this.functionMap[functionName](field?.formControl?.value, true);
            
          }
        }
      }
    }
   

     // Recursively bind functions for nested field groups
     if (field.fieldGroup) {
      this.bindFunctionsToTemplateOptions(field.fieldGroup);
    }
    if (field.templateOptions?.changeFunction) {
      const functionName = field.templateOptions.changeFunction;
      if (this.functionMap[functionName]) {
        field.templateOptions.selectionChange = (field: FormlyFieldConfig,event:Event) => {
        if(functionName==='onResidentialCountryChange'){
        if(field?.formControl?.value){
        this.functionMap[functionName](field?.formControl?.value);
        }
      }  else  if(functionName==='onInMailingCountryChange'){
        if(field?.formControl?.value){
        this.functionMap[functionName](field?.formControl?.value);
        }
      }
    }
  }
}
  });
}

findFieldByKey(fields: FormlyFieldConfig[], key: string): FormlyFieldConfig | null {
  for (const field of fields) {
    if (field.key === key) {
      return field;
    }
    if (field.fieldGroup && field.fieldGroup.length) {
      const nestedField = this.findFieldByKey(field.fieldGroup, key);
      if (nestedField) {
        return nestedField;
      }
    }
  }
  return null;
}

getValueByKey(key: string, type?) {
  return this.form.get(key)?.value;
}

getCountryOfOrigin(search?, type?) {
  let countryOfOriginAutoCompleteField: any
    countryOfOriginAutoCompleteField = this.findFieldByKey(this.fields, 'countryOfOrigin');
  
  if(search !=  undefined && search && search.length > 0){
      this.spinner.show();
      const apiData = {
       instance_id: localStorage.getItem('instance_id'),
        email: localStorage.getItem('email'),
        search: search
      };
      this._api.functionPOST('web/allCountriesList', apiData).subscribe((response) => {
        // console.log('----------------data', response['data']);
        this.updateFieldOptions('countryOfOrigin', response['data'].map((country: { bt_country_id: any; name: any; }) => ({
          value: country.bt_country_id,
          label: country.name 
        })));
        this.spinner.hide();
      },
        err => {
        });
  }else {
    // console.log('-------------');
    if (countryOfOriginAutoCompleteField) {
      countryOfOriginAutoCompleteField.formControl?.setValue('', { emitEvent: false });
      this.isCountryOriginData = false;
    }
  }
  }

 getSelectedCountryOfOrigin(data: any) {
    // console.log('---------data', data);
    let countryOfOriginAutoCompleteField: any;
      countryOfOriginAutoCompleteField = this.findFieldByKey(this.fields, 'countryOfOrigin');
      this.model['countryOfOrigin'] = data.label;
    if (countryOfOriginAutoCompleteField && countryOfOriginAutoCompleteField.formControl?.value !== data.label) {
      countryOfOriginAutoCompleteField.formControl?.setValue(data.label, { emitEvent: false });
      this.isCountryOriginData = true;
      
    }
  }

// get country list
getCountryList(): Observable < boolean > {
  return new Observable(observer => {
  this.spinner.show();
  const apiData = {
   instance_id: localStorage.getItem('instance_id'),
    email: localStorage.getItem('email')
  };
  this._api.functionPOST('web/countriesList', apiData).subscribe((res) => {
    this.updateFieldOptions('residentialCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));
    this.updateFieldOptions('indMailingCountry', res['data'].map((country: { bt_country_id: any; name: any; }) => ({
      value: country.bt_country_id,
      label: country.name 
    })));
    observer.next(true);
    observer.complete();
    this.spinner.hide();
  },
    err => {
    });
  });
}

  onResidentialCountryChange(event: any) {
    this.residentialZip = '';
        this.selectedResidentialCountryID = event;
        const fieldsToClear = ['residentialState', 'residentialCity', 'residentialZip'];
        fieldsToClear.forEach(key => {
          const field = this.findFieldByKey(this.fields, key);
          if (field && field.formControl) {
            field.formControl.setValue(null, { emitEvent: false });
            field.formControl.markAsUntouched(); 
            field.formControl.markAsPristine(); 
          }
        });
  }
  
  updateFieldOptions(key: string, options: any[]) {
      const field = this.findFieldByKey(this.fields, key);
      if (field && field.templateOptions) {
        field.templateOptions.options = options;
      }
  
  }

  ValidateResidentialZipCode(zip_code?, type?) {

    const zipCode = zip_code;
    const stateField = this.findFieldByKey(this.fields, 'residentialState');
    const cityField = this.findFieldByKey(this.fields, 'residentialCity');
    const zipCodeField = this.findFieldByKey(this.fields, 'residentialZip');
    console.log('--------------------zip', zipCode);
    if(zipCode && this.residentialZip !== zipCode){
      this.spinner.show();
      const apiData = {
        zipcode: zipCode,
        Country: this.selectedResidentialCountryID
      };
      this._api.functionPOST('zipcode/findZip', apiData).subscribe((response) => {
        
        if(response['success'] && response['data'].length > 0){
               //this.model['residentialZip'] = response['data'][0]['bt_zipcodes_id'];
              if (stateField) {
                stateField.formControl?.setValue(response['data'][0]['bt_region']['name'], { emitEvent: false });
               
                  this.model['residentialState'] = response['data'][0]['bt_region']['name'];
                
              }
              if (cityField) {
                cityField.formControl?.setValue(response['data'][0]['city'], {emitEvent: false});
                this.model['residentialCity'] = response['data'][0]['city'];
                  this.model['residentialCity'] = response['data'][0]['city'];
              }
        } else {
          this.residentialZip = '';
          this._api.openSnackBar('Invalid zipcode', 'Error');
            if (stateField) {
              stateField.formControl?.setValue('', { emitEvent: false });
            }
            if (cityField) {
              cityField.formControl?.setValue('', {emitEvent: false});
            }
            if (zipCodeField) {
              zipCodeField.formControl?.setValue('', {emitEvent: false});
            }
        }
        this.spinner.hide();
      },
        err => {
        });
      }
      if(!zipCode){
        if (stateField) {
          stateField.formControl?.setValue('', { emitEvent: false });
        }
        if (cityField) {
          cityField.formControl?.setValue('', {emitEvent: false});
        }
        if (zipCodeField) {
          zipCodeField.formControl?.setValue('', {emitEvent: false});
        }
      }
      this.residentialZip = zipCode;
  }

  ValidateMailingZipCode(zip_code?, type?) {
    let mailingStateField: any;
    let mailingCityField: any;
    let mailingZipCodeField: any;
    const zipCode = zip_code;
       mailingStateField = this.findFieldByKey(this.fields, 'mailingState');
       mailingCityField = this.findFieldByKey(this.fields, 'mailingCity');
       mailingZipCodeField = this.findFieldByKey(this.fields, 'mailingZip');
     
    if(zipCode && this.indMailingZip!=zipCode){

    
      this.spinner.show();
      const apiData = {
        zipcode: zipCode,
        Country: this.selectedMailingCountryID
      };
      this._api.functionPOST('zipcode/findZip', apiData).subscribe((response) => {

          if(response['data'].length > 0){

            if (mailingStateField) {
              mailingStateField.formControl?.setValue(response['data'][0]['bt_region']['name'], { emitEvent: false });
              
                this.model['mailingState'] = response['data'][0]['bt_region']['name'];
            }
            if (mailingCityField) {
              mailingCityField.formControl?.setValue(response['data'][0]['city'], {emitEvent: false});
             
                this.model['mailingCity'] = response['data'][0]['city'];
              
            }

          } else {
            this.indMailingZip = '';
            this._api.openSnackBar('Invalid zipcode', 'Error');
            if (mailingStateField) {
              mailingStateField.formControl?.setValue('', { emitEvent: false });
            }
            if (mailingCityField) {
              mailingCityField.formControl?.setValue('', {emitEvent: false});
            }
            if (mailingZipCodeField) {
              mailingZipCodeField.formControl?.setValue('', {emitEvent: false});
            }
          }
          this.spinner.hide();
        },
        err => {
        });
      }
      if(!zipCode){
        if (mailingStateField) {
          mailingStateField.formControl?.setValue('', { emitEvent: false });
        }
        if (mailingCityField) {
          mailingCityField.formControl?.setValue('', {emitEvent: false});
        }
        if (mailingZipCodeField) {
          mailingZipCodeField.formControl?.setValue('', {emitEvent: false});
        }
      }
      this.indMailingZip = zipCode;
          
  }

  close() {
    this.form.reset();
    this.dialogRef.close();
  }

  addIndividualDetails() {
    if (this.form.valid) {
      this.dialogRef.close(this.model);
    }else{
      this._api.openSnackBar('Please complete all required fields.', 'Error');
    }
  }

// Serach License
  filterValidation(tag){
    let u_search_f = this.searchinput.get('search_field').value;

    if (!u_search_f) {
      this._api.openSnackBar('Search field is empty.', 'Error');
      return
    }else{
      this.getLicDetails({ page: 1 });
    }
  }

  getLicDetails(data?) {
  const apiData = { 
    'search_field': this.searchinput.get('search_field').value, 
   instance_id: localStorage.getItem('instance_id'),
    email: localStorage.getItem('email')
    }
    if (this.sortcolumn !== '') {
      apiData['sortcolumn'] = this.sortcolumn;
    }
    if (this.sortorder !== '') {
      apiData['sortorder'] = this.sortorder;
    }

    this.spinner.show();
    this._api.functionPOST('web/licenseSearch', apiData).subscribe((response) => {
      this.license_data = response.data.data
      this.spinner.hide();
    });
  }

  sortData($event) {
    this.sortcolumn = $event.active;
    this.sortorder = $event.direction.toUpperCase();
    this.page.index=0
    this.getLicDetails({ page: 1 });
  }

  PageChange($event) {
    this.page.pageSize = $event.pageSize;
    this.page.index = $event.pageIndex;
    this.getLicDetails({ page: $event.pageIndex + 1 });
  }

   onCheckboxChange(user: any, event: any) {
     if (event.target.checked) {
       this.selectedUsers.push(user);
     } else {
       this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
     }
   }

   submitSelectedRow() {
    this.dialogRef.close(this.selectedUsers);

   }

  getSupportedFiles() {
    const apiData = {
     instance_id: localStorage.getItem('instance_id'),
      email: localStorage.getItem('email')
    };
    this._api.functionPOST('web/getSupportedFileDetails', apiData).subscribe((res) => {
      if (res['data'] ) {
        const files   =  res['data']['supported_file_types'];
        this.supportedDocType = files.split(',');
      }
      var size = (res['data']['max_file_size_bytes']/1000000)+' MB';
      this.supportedFile={
        maxSize:size,
        fileSize:res['data']['max_file_size_bytes'],
        type:res['data']['supported_file_types']
      };
      this.max_file_count = parseInt(res['data']['max_file_count'])
     this.supporetsFiles = '* Supported document types are ' + res['data']['supported_file_types'];
     this.supportMaxFileSize = '* Supported maximum file count is '+ this.max_file_count +
     ' and file size is ' + this.supportedFile['maxSize'];
    },
      err => {
      });
  }

  
  onFileSelected(event: any) {
    if(this.AttachFiles.length>=this.max_file_count){
      this._api.openSnackBar('Supported maximum file count should be less than or equal to '+this.max_file_count, 'Error');
      return;
    }
    const selectedFile = event.target.files[0];
    if(this.AttachFiles.length>=this.max_file_count){
      this._api.openSnackBar('Supported maximum file count should be less than or equal to '+this.max_file_count, 'Error');
      return;
    }
    this.AttachFiles = [selectedFile];
    this.attachNames = [selectedFile.name];


  //   for (let i = 0; i <= event.target.files.length - 1; i++) {
  //     const file = event.target.files[i];
  //     console.log('-------------file', file)
  // const selectedFile = event.target.files[i];
  // if(this.AttachFiles.length>=this.max_file_count){
  //   this._api.openSnackBar('Supported maximum file count should be less than or equal to '+this.max_file_count, 'Error');
  //   return;
  // }
  // this.AttachFiles.push(selectedFile);
  // this.attachNames.push(selectedFile.name);
  // }
  // this.attachInputRef.nativeElement.value = '';
  }

  openPopUpForCaptureImg(){
    const dialogRef = this.dialog.open(CaptureImageFromCameraComponent, {
      maxWidth: '600px'
      , data: {
        pageTitle: 'Capture Photo',
        message: this.docTitle,
        pageType: 'CAPTURE_PHOTO',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.model.latitude = result.latitude;
        // this.model.longitude = result.longitude;
        this.timestamp = result.timestamp;
        this.capturedPhotoFromModal = result.image;
      }
    });
  }

  convertDataURLToBlob(dataURL: string): Blob {
    const parts = dataURL.split(',');
    const mimeType = parts[0].match(/:(.*?);/)[1];
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([uint8Array], { type: mimeType });
  }
  
  
  uploadNotAppDoc() {
    const comments = this.docForm.get('comments').value;
    if(!comments){
      this._api.openSnackBar('Enter Comments', 'Error');
      return false;

    }
    const docComments = this.docForm.get('comments').value;
      const apiData = new FormData();
      apiData.append('instance_id', localStorage.getItem('instance_id'));
      apiData.append('email', localStorage.getItem('email'));
      apiData.append('doc_type', this.docTitle);
      apiData.append('doc_comment', docComments);
      apiData.append('isNotApplicable', 'Y');
      this._api.functionPOST('web/uploadDocument' , apiData).subscribe((response) => {
        if(response['success']){
          this._api.openSnackBar(response['data'] , 'Success');
          this.selectedFile = '';
          this.apiImage = '';
          this.docForm.reset();
          this.dialogRef.close(true);
        }
      },
      err => {
        this._api.openSnackBar(err.error, 'Error');
      });
      this.spinner.hide();
    }
  
  uploadAppDoc() {
    
    this.formSubmitted = true;
    const radioSelect = this.docForm.get('appDoc').value;
     if(!radioSelect){
        this._api.openSnackBar('Please select an option.', 'Error');
        return false;
      } else if(radioSelect === 'upload_from_device' && this.AttachFiles.length === 0){
        this.capturedPhotoFromModal = '';
        this._api.openSnackBar('Select a file', 'Error');
        return false;

      }else if(radioSelect === 'upload_from_mobile' && !this.capturedPhotoFromModal){
        this.AttachFiles = [];
        this._api.openSnackBar('Take Photo', 'Error');
        return false;
      }
      
    const docComments = this.docForm.get('comments').value;
      const apiData = new FormData();
      if (this.AttachFiles.length > 0 && radioSelect === 'upload_from_device') {
          this.AttachFiles.map((item) => {
            apiData.append('files', item);
          });
        }

        if(this.capturedPhotoFromModal && radioSelect === 'upload_from_mobile'){
          const blob = this.convertDataURLToBlob(this.capturedPhotoFromModal);
          const fileName = `${this.docTitle}_${this.timestamp}.png`;
          const file = new File([blob], fileName, { type: blob.type });
          apiData.append('files', file);
        }

      apiData.append('instance_id', localStorage.getItem('instance_id'));
      apiData.append('email', localStorage.getItem('email'));
      apiData.append('doc_type', this.docTitle);
      apiData.append('doc_comment', docComments);
      apiData.append('is_pii', 'N');
      apiData.append('isNotApplicable', 'N');
      setTimeout(() => {
      this.spinner.show();
      this._api.functionPOST('web/uploadDocument' , apiData).subscribe((response) => {
        if(response['success']){
          this._api.openSnackBar(response['data'] , 'Success');
          this.selectedFile = '';
          this.apiImage = '';
          this.docForm.reset();
          this.dialogRef.close(true);
        }
      },
      err => {
        this._api.openSnackBar(err.error, 'Error');
      });
    }, 1000);
      this.spinner.hide();
    }
   
}
