import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { BasicPopupComponent } from '../shared/popups/basic-popup/basic-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-domain',
  templateUrl: './verify-domain.component.html',
  styleUrls: ['./verify-domain.component.css']
})
export class VerifyDomainComponent implements OnInit {

  userForm: FormGroup;
  token: string | null | undefined;
  appId: string | null | undefined;
  appData: any;
   isV2CaptchaRequired: boolean = false;
   siteKey: string = environment.reCaptcha2SiteKey

   isgetEmail: boolean = false;

  constructor(
    private route: ActivatedRoute
   , private router: Router
   , private _api: ApiService
   , private spinner: NgxSpinnerService
   , private formBuilder: FormBuilder
   , private cookieService: CookieService
   , private dialog: MatDialog
   ) {
     this.userForm = formBuilder.group({
       email: new FormControl('', [Validators.required, Validators.email])
     })
   }
 
   ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.token = params.get('token');
      this.appId = params.get('appid');
      localStorage.setItem('authToken', params.get('token'));
      this.getEmail();
    });
   }

   formValidation(tag: number){
     let email = this.userForm.get('email')?.value;
     if(!email){
       if(tag==1){
         this._api.openSnackBar('Enter Email', 'Error');
       }
       return;
     }
   this.submitDomainVerification();
   }

 getEmail(v2token?){
  let isV2Required;
  if(v2token){
    isV2Required = true;
  }
  this.spinner.show();
  const apiData = {
    instance_id: this.appId,
    token: this.token,
    isV2CaptchaRequired: isV2Required
  };
  this._api.functionPOST('web/getEmailByAppId', apiData, v2token).subscribe((response) => {

    this.appData = response['data'];
    if(response['success'] && response['data']['email']){
      this.isV2CaptchaRequired = false;
      this.isgetEmail = true;
      
      if(this.appData.is_verified){
        const dialogRef = this.dialog.open(BasicPopupComponent, {
          maxWidth: '600px'
          , data: {
            pageTitle: "Alert",
            pageType: 'email_verified',
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.router.navigate(['/']);
          }
        });
      }else{
        
        this.userForm.patchValue({
          email: this.appData.email
        })
        
      }
  }else {
    this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];
    this.isgetEmail = false;
    if(response['data']['message']){
      this._api.openSnackBar(response['data']['message'], 'Error');
      this.router.navigate(['/']);
    }
  }
  
  this.spinner.hide();
  }, err => {
    this.isV2CaptchaRequired = false;
  });
}

submitDomainVerification(v2token?){
  this._api.checkLocationAccess().subscribe((isAllowed) => {
    if (isAllowed) {
  let isV2Required;
  if(v2token){
    isV2Required = true;
  }
    let email = this.userForm.get('email')?.value;
    this.spinner.show();
    const apiData = {
      instance_id: this.appId,
      email: email,
      latitude: localStorage.getItem('latitude'),
      longitude:  localStorage.getItem('longitude'),
      isV2CaptchaRequired: isV2Required

    };
    this._api.functionPOST('web/validateEmail', apiData, v2token).subscribe((response) => {
      localStorage.setItem('firstname', response['data']['firstname']);
      localStorage.setItem('lastname', response['data']['lastname']);
      localStorage.setItem('instance_id', response['data']['df_instance_id']);
      localStorage.setItem('authToken', response['data']['token']);
      localStorage.setItem('email', response['data']['email']);

    if(response['data']['token']){
      this.router.navigate(['selected-application/'+response['data']['df_instance_id']]);
    }else {
      this.isV2CaptchaRequired = response['data']['isV2CaptchaRequired'];

      // this._api.openSnackBar(response['errorDescription'], 'Error');
    }
    this.spinner.hide();
    });
  }});
 }
 
 handleSuccess(captchaResponse: string): void {
  localStorage.setItem('authToken', this.token);
  // this.cookieService.set('token', this.token);
  // console.log('CAPTCHA Response:', captchaResponse, localStorage.getItem('authToken'));
  
  if(!this.isgetEmail){
    this.getEmail(captchaResponse);
  }else{
    this.submitDomainVerification(captchaResponse);
  }
  
  // Use the response token for server-side validation
}

handleError(error: any): void {
  console.error('CAPTCHA Error:', error);
}

handleExpired(): void {
  console.warn('CAPTCHA Expired');
  // Optionally reset the CAPTCHA if needed
}

}
